import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({ name: 'shortTimespan' })
export class ShortTimespanPipe implements PipeTransform {
  transform(value: string): string | boolean {
    if (!value) {
      return value;
    }
    const inputDuration = moment.duration(value);
    const generateTimeUnit = (args: { numberOfUnits: number, unit: string }) => {
      const { numberOfUnits, unit } = args;
      return numberOfUnits <= 0 ? '' : `${numberOfUnits} ${unit}`;
    };
    const duration = [
      { numberOfUnits: inputDuration.hours(), unit: 'h' },
      { numberOfUnits: inputDuration.minutes(), unit: 'm' }
    ].map(generateTimeUnit).join(' ');
    return inputDuration > moment.duration(1, 'm') ? `${duration}` : 'now';
  }
}
