import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { getErrorMessage } from '../utils/Utils';
import { SharedModule } from '../shared.module';
import { ApiErrorOptions } from '../models/ApiErrorOptions';
import { DialogService } from './dialog.service';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: SharedModule
})
export class ApiService {

  private readonly baseURL = "";

  constructor(
    private http: HttpClient,
    private dialogService: DialogService
  ) {
  }

  makeGet<T>(url: string, errorOptions?: ApiErrorOptions): Observable<T> {
    return this.http.get<T>(this._getUrl(url))
      .pipe(
        catchError(err => {
          if (!!errorOptions) {
            setTimeout(() => {
              this.dialogService.showError(errorOptions.title, getErrorMessage(err, null, errorOptions.errorPrefix, errorOptions.errorSuffix), { showConfirmButton: errorOptions.showConfirmButton });
            }, 0);
          }
          return throwError(err);
        })
      );
  }

  makeGetParams<T>(url: string, param: any, errorOptions?: ApiErrorOptions): Observable<T> {
    if (!param) param = {};

    return this.http.get<T>(this._getUrl(url), { params: param })
      .pipe(
        catchError(err => {
          if (!!errorOptions) {
            setTimeout(() => {
              this.dialogService.showError(errorOptions.title, getErrorMessage(err, null, errorOptions.errorPrefix, errorOptions.errorSuffix), { showConfirmButton: errorOptions.showConfirmButton });
            }, 0);
          }
          return throwError(err);
        })
      );
  }

  makePost<T>(url: string, param: any, errorOptions?: ApiErrorOptions): Observable<T> {
    if (!param) param = {};

    return this.http.post<T>(this._getUrl(url), param)
      .pipe(
        catchError(err => {
          if (!!errorOptions) {
            setTimeout(() => {
              this.dialogService.showError(errorOptions.title, getErrorMessage(err, null, errorOptions.errorPrefix, errorOptions.errorSuffix), { showConfirmButton: errorOptions.showConfirmButton });
            }, 0);
          }

          return throwError(err);
        })
      );
  }

  makePut<T>(url: string, param: any, errorOptions?: ApiErrorOptions): Observable<T> {
    if (!param) param = {};

    return this.http.put<T>(this._getUrl(url), param)
      .pipe(
        catchError(err => {
          if (!!errorOptions) {
            setTimeout(() => {
              this.dialogService.showError(errorOptions.title, getErrorMessage(err, null, errorOptions.errorPrefix, errorOptions.errorSuffix), { showConfirmButton: errorOptions.showConfirmButton });
            }, 0);
          }
          return throwError(err);
        })
      );
  }

  makeDelete<T>(url: string, errorOptions?: ApiErrorOptions): Observable<T> {
    return this.http.delete<T>(this._getUrl(url))
      .pipe(
        catchError(err => {
          if (!!errorOptions) {
            setTimeout(() => {
              this.dialogService.showError(errorOptions.title, getErrorMessage(err, null, errorOptions.errorPrefix, errorOptions.errorSuffix), { showConfirmButton: errorOptions.showConfirmButton });
            }, 0);
          }
          return throwError(err);
        })
      );
  }

  private _getUrl(endpoint: string) {
    return `${this.baseURL}${endpoint}`;
  }
}
