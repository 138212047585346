import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'shared-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss']
})
export class StatusComponent implements OnInit {
  constructor() { }

  @Input() status;

  ngOnInit() { }
}
