import { Moment } from 'moment';
import { Instance } from 'flatpickr/dist/types/instance';

export class DatePickerSelection {
  public start: Moment;
  public end: Moment;
  public instance: Instance;

  constructor(start: Moment, end: Moment) {
    this.start = start;
    this.end = end;
  }
}

