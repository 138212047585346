import * as moment from 'moment';
import { ValueFormatterParams } from 'ag-grid-community';

export const DEFAULT_DATE_FORMAT: string = 'DD/MM/YYYY';
export const DEFAULT_DATE_AND_TIME_FORMAT: string = 'DD/MM/YYYY HH:mm';
export const FULL_DATE_AND_TIME_FORMAT: string = 'DD/MM/YYYY HH:mm:ss';
export const DEFAULT_HOUR_FORMAT: string = 'YYMMDD-HH';

export function DateFormatter(dateFormat: string = DEFAULT_DATE_FORMAT) {
  return (params: ValueFormatterParams) => {
    return formatDate(params.value, dateFormat);
  };
}

export function formatDate(dateValue: string, dateFormat: string = DEFAULT_DATE_FORMAT, isLocal: boolean = false): string {
  if (isLocal)
    return dateValue ? moment.utc(dateValue).local().format(dateFormat) : '';
  return dateValue ? moment.utc(dateValue).format(dateFormat) : '';
}

export function formatDateOrNA(dateValue: string) {
  if (!dateValue) return 'N/A';
  return formatDate(dateValue);
}

export function combineArray(a: any[], prop: string, delimiter: string = ', '): string {
  if (!a || a.length === 0) return '';

  let text = '';
  for (let item of a) {
    text += (prop ? item[prop] : item) + delimiter;
  }
  return text.substring(0, text.length - delimiter.length);
}

export function getNoUISliderDefaultFormatter() {
  return {
    'to': function (value) {
      return value;
    },
    'from': Number
  };
}

export function DecimalFormatter(numberOfDecimals: number = 0) {
  return (params: ValueFormatterParams) => {
    return formatDecimal(params.value, numberOfDecimals);
  };
}

let numberStyles = new Map<number, object>();

export function formatDecimal(value: number, numberOfDecimals: number = 0): string {
  let numberStyle;

  if (numberStyles.has(numberOfDecimals)) {
    numberStyle = numberStyles.get(numberOfDecimals);
  }
  else {
    numberStyle = new Intl.NumberFormat('en-GB', {
      minimumFractionDigits: numberOfDecimals,
      useGrouping: false,
    });

    numberStyles.set(numberOfDecimals, numberStyle);
  }

  return numberStyle.format(value);
}

export function formatConstantTitle(value: string): string {
  return value.replace('_', ' ').replace(/\w\S*/g, function (word) { return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase(); });
}
