import { Component, OnInit, ViewChild, TemplateRef, AfterViewInit } from '@angular/core';
import { takeUntil, finalize } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { RowNode } from 'ag-grid-community';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { UserService } from '../../../services/user.service';

import { DialogService } from '../../../shared/services/dialog.service';
import { ValidationService } from '../../../shared/services/validation.service';
import { FooterService } from '../../../services/footer.service';

import { User } from '../../../models/user/User';
import { UserAsset } from '../../../models/user/UserAsset';
import { UserRelatedData } from '../../../models/user/UserRelatedData';
import { UserLevels } from '../../../models/user/UserLevel';

import { GridComponentBase } from '../../../shared/components/base/GridComponent';

import { AgGridUtils } from '../../../shared/utils/AgGridUtils';
import { clone, getModalOptions } from '../../../shared/utils/Utils';
import { compareEntitiesById } from '../../../shared/utils/Comparers';
import { IMultiSelectSettings, IMultiSelectTexts } from 'angular-2-dropdown-multiselect';

@Component({
  selector: 'orbit-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss']
})
export class UserManagementComponent extends GridComponentBase<User> implements OnInit, AfterViewInit {
  constructor(
    private userService: UserService,
    private dialogService: DialogService,
    private footerService: FooterService,
    private validationService: ValidationService,
  ) {
    super();

    this.addColumnType('centerColumn', AgGridUtils.getCenterColumn());
    this.addColumnType('setColumn', AgGridUtils.getSetColumn());

    this.addColumnDefs(
      [
        { field: 'email', headerName: 'Email', minWidth: 100, width: 200, sort: 'asc' },
        { field: 'name', headerName: 'Full Name', minWidth: 100, width: 200, },
        { valueGetter: p => p.data.level ? p.data.level.name : 'N/A', type: ['setColumn'], headerName: 'Level', minWidth: 120, width: 180, maxWidth: 180 },
        { valueGetter: p => p.data.isActive ? 'Active' : 'Inactive', type: ['setColumn', 'centerColumn'], headerName: 'Status', minWidth: 90, maxWidth: 100, resizable: false },
        { valueGetter: p => p.data.isSetup ? 'Yes' : 'No', type: ['setColumn', 'centerColumn'], headerName: 'Account Setup', minWidth: 140, width: 140, maxWidth: 140, resizable: false },
        {
          headerName: 'Reset',
          headerClass: 'text-center',
          cellRenderer: 'buttonCellRenderer',
          cellRendererParams: {
            buttonClass: 'btn btn-success btn-xs',
            buttonText: '',
            iconClass: 'fas fa-undo-alt white-color',
            onClick: this._onResetPassword.bind(this)
          },
          minWidth: 90,
          width: 90,
          maxWidth: 90,
          sortable: false,
          filter: false,
          resizable: false,
          suppressSizeToFit: true
        },
      ]
    );

    this.footerService.showAddButton = true;
    this.footerService.showEditButton = true;
    this.footerService.showDeleteButton = true;

    this.footerService.onAddHandler = this._onAdd.bind(this);
    this.footerService.onEditHandler = this._onEdit.bind(this);
    this.footerService.onDeleteHandler = this._onDelete.bind(this);

    this.onDestroy = () => {
      this.footerService.clearFooter();
    };

    this.gridOptions.overlayNoRowsTemplate = '<span class="ag-overlay-no-rows-center">No users defined for the application.</span>';
  }

  //#region Fields

  @ViewChild('footerContent') footerContent: TemplateRef<any>;
  @ViewChild('userModal') modal: ModalDirective;
  modalConfig = getModalOptions();

  userData: UserRelatedData;
  isValidating: boolean;

  compareById = compareEntitiesById;

  assetMultiSelectSettings: IMultiSelectSettings = {
    enableSearch: true,
    checkedStyle: 'fontawesome',
    buttonClasses: 'btn btn-default btn-block',
    dynamicTitleMaxItems: 4,
    displayAllSelectedText: true,
    showCheckAll: true,
    showUncheckAll: true,
    closeOnClickOutside: true,
    closeOnSelect: false
  };

  assetMultiSelectTexts: IMultiSelectTexts = {
    checked: 'asset',
    checkedPlural: 'assets',
    defaultTitle: 'No assets',
    allSelected: 'All assets'
  };
  currentSelectionAssetIds: number[];

  //#endregion

  //#region Init

  ngOnInit() {
    this._initData();
  }

  ngAfterViewInit() {
    this.subscriptions.push(this.footerService.injectView(this.footerContent));
  }

  private _initData() {
    this.startLoader();

    forkJoin([
      this.userService.getUsers(),
      this.userService.getUserRelatedData()
    ]).pipe(
      finalize(() => this.stopLoader())
    ).subscribe(result => {
      this.data = result[0];
      this.userData = result[1];
    });
  }

  //#endregion

  //#region Event Handlers

  public onRowSelected($event: any) {
    if (!$event.node.isSelected()) return;
    this.currentSelection = clone($event.data);
    this.currentSelectionIdx = this.data.indexOf(this.data.find(u => u.id == this.currentSelection.id));

    this.footerService.updateSelectedItem(this.currentSelection);
  }

  public onRowDoubleClicked(_: any) {
    this._onEdit();
  }

  private _onAdd() {
    this.updatedItem = new User();
    this.updatedItem.isActive = true;
    this.currentSelectionAssetIds = [];
    this.isValidating = false;
    this.modal.toggle();
  }

  private _onEdit() {
    this.updatedItem = clone(this.currentSelection);
    this.currentSelectionAssetIds = this.updatedItem.assets.map(asset => asset.assetId);
    this.isValidating = false;
    this.modal.toggle();
  }

  public cancelChanges() {
    this.updatedItem = null;
    this.modal.hide();
  }

  public saveChanges() {
    this.isValidating = true;
    if (!this.isModelValid()) return;
    this.isValidating = false;

    // If the user level is Administrator, set the assets to empty
    if (this.updatedItem.level && this.updatedItem.level.id === UserLevels.Administrator) {
      this.updatedItem.assets = [];
    }

    this.dialogService.showLoadingDialog('Saving Changes');

    this.userService.updateUserData(this.updatedItem)
      .pipe(takeUntil(this.ngUnsubscribe), finalize(() => {
        this.updatedItem = null;
        this.modal.hide();
      }))
      .subscribe(user => {
        if (!!this.updatedItem.id) {
          // update
          this.data[this.currentSelectionIdx] = user;
          this.updateGridAfterEdit(this.updatedItem);
          this.currentSelection = clone(this.updatedItem);
        }
        else {
          // add
          this.data.push(user);
          this.updateGridAfterAdd(user);
          this.gridOptions.api.deselectAll();
          this.currentSelection = undefined;
          this.footerService.updateSelectedItem(this.currentSelection);
        }

        this.dialogService.showDialog('Success!', 'User details updated successfully.', 'success');
      });
  }

  private _onDelete() {
    this.dialogService.showDialog({
      title: `Are you sure you want to delete the user account for ${this.currentSelection.name}?`,
      text: 'The user will be completely removed and cannot be recovered.',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#FFFFFF',
      confirmButtonText: 'Delete'
    }).then(result => {
      if (!!result.value) {
        this.dialogService.showDialog({
          title: 'Processing',
          text: 'Deleting selected user',
          allowOutsideClick: false,
          onOpen: () => {
            this.dialogService.showLoading();
          }
        });

        this.userService.deleteUser(this.currentSelection.id)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe(() => {
            this.data.splice(this.currentSelectionIdx, 1);
            this.updateGridAfterDelete();

            this.currentSelection = null;
            this.footerService.updateSelectedItem(this.currentSelection);

            this.dialogService.showDialog('Success!', 'Selected user deleted successfully.', 'success');
          });
      }
    });
  }

  private _onResetPassword(node: RowNode) {
    this.currentSelection = node.data;
    this.setCurrentSelection();

    this.dialogService.showDialog({
      title: `Are you sure you want to reset the user account for ${this.currentSelection.name}?`,
      text: 'A token address will be generated for the user to access and set a new password.',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#FFFFFF',
      confirmButtonText: 'Reset'
    }).then(result => {
      if (!!result.value) {
        this.dialogService.showDialog({
          title: 'Processing',
          text: 'Generating token',
          allowOutsideClick: false,
          onOpen: () => {
            this.dialogService.showLoading();
          }
        });

        this.userService.resetPassword(this.currentSelection.id)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((tokenData) => {
            const token = this._getSetupUrl(tokenData.token);

            this.dialogService.showDialog({
              title: `Token generated successfully:`,
              text: token,
              type: 'success',
              showCancelButton: false,
              confirmButtonColor: '#FFFFFF',
              confirmButtonText: 'Copy Token',
            }).then((result) => {
              if (!!result.value) {
                this._initData();

                this._copyToken(token);
              }
            });
          });
      }
    });
  }

  public onGetActivationToken() {
    if (!this.currentSelection) return;

    this.dialogService.showDialog({
      title: 'Processing',
      text: 'Generating token',
      allowOutsideClick: false,
      onOpen: () => {
        this.dialogService.showLoading();
      }
    });

    this.userService.getActivationToken(this.currentSelection.id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((tokenData) => {
        const token = this._getSetupUrl(tokenData.token);

        this.dialogService.showDialog({
          title: `Token generated successfully:`,
          text: token,
          type: 'success',
          showCancelButton: false,
          confirmButtonColor: '#FFFFFF',
          confirmButtonText: 'Copy Token',
        }).then((result) => {
          if (!!result.value) {
            this._copyToken(token);
          }
        });
      });
  }

  onResetLocks() {
    this.dialogService.showDialog({
      title: `Are you sure you want to reset the account locks?`,
      text: 'This will remove any existing locks for all accounts and allow them to login again.',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Reset'
    }).then(result => {
      if (!!result.value) {
        this.dialogService.showDialog({
          title: 'Processing',
          text: 'Resetting locks',
          allowOutsideClick: false,
          onOpen: () => {
            this.dialogService.showLoading();
          }
        });
        this.userService.resetLockouts()
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe(_ => {
            this.dialogService.showDialog('Success!', 'Account locks successfully reset.', 'success');
          });
      }
    });
  }

  onClearFilters() {
    this.gridOptions.api.setFilterModel(null);
  }

  onAssetsSelectChange(_: any) {
    this.updatedItem.assets = this.currentSelectionAssetIds.map(id => <UserAsset><unknown>({ userId: this.updatedItem.id, assetId: id }));
  }

  //endregion

  //#region Helpers

  private _copyToken(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  resetSelection(): void {
    this.footerService.updateSelectedItem(undefined);
  }

  isAdminSelected() {
    return this.updatedItem.level && (this.updatedItem.level.id == UserLevels.Administrator);
  }

  private _getSetupUrl(token: string) {
    return `${window.location.origin}/#/setup/${token}`;
  }

  //#endregion

  //#region Validation

  isModelValid(): boolean {
    return !!this.updatedItem
      && !!this.updatedItem.email
      && !!this.updatedItem.name
      && !!this.updatedItem.level
      //at least one asset must be selected if level is not admin
      && (this.updatedItem.level.id === UserLevels.Administrator || (this.updatedItem.assets && this.updatedItem.assets.length > 0));
  }

  isAdmin() {
    return this.updatedItem.level && (this.updatedItem.level.id === UserLevels.Administrator);
  }

  hasValidAssets(): boolean {
    const hasAssets = this.currentSelectionAssetIds && this.currentSelectionAssetIds.length > 0;

    // If not an admin, user must have at least one asset selected
    return this.isAdmin() || hasAssets;
  }

  validateModel(prop: string): string {
    switch (prop) {
      default:
        return this.validationService.validateRequired(this.updatedItem[prop]);
    }
  }

  //#endregion
}