import { Injectable } from '@angular/core';
import { clone } from '../utils/Utils';
import { UUID } from 'angular2-uuid';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class SessionInfoService {
  constructor() {
    this._generateSessionId();
  }

  _sessionId: string;
  private _generateSessionId() {
    this._sessionId = `${UUID.UUID()}:${moment().toISOString()}`;
  }

  getSessionId(): string {
    return clone(this._sessionId);
  }
}