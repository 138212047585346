import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { UserService } from '../services/user.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private userService: UserService,
    private router: Router
  ) { }

  canActivate(): boolean {
    if (!!this.userService.isAuthenticated()) return true;

    // User is not authenticated, redirect to the Login page
    this.router.navigate(['/login']);
    return false;
  }
}