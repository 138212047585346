import { Component, OnChanges, Input, SimpleChanges, SimpleChange, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { modalHideEvent } from '../../utils/Utils';
import { SelfUnsubscriberBase } from '../base/SelfUnsubscriber';

@Component({
  selector: 'input-wrapper',
  templateUrl: './input-wrapper.component.html',
  styleUrls: ['./input-wrapper.component.scss']
})
export class InputWrapperComponent extends SelfUnsubscriberBase implements OnInit, OnChanges {

  @Input() labelText: string;
  @Input() labelClass: string;
  @Input() contentClass: string;
  @Input() model: any;
  @Input() control: any;
  @Input() validate: (prop: string) => string;
  @Input() validationProperty: string;
  @Input() forceValidation: boolean = false;
  @Input() tips: string;

  errorMessage: string = '';

  ngOnInit() {
    this.subscriptions.push(modalHideEvent.subscribe(() => {
      if (this.control) {
        const ngForm = (<NgForm>this.control.formDirective);
        if (!ngForm) return;
        ngForm.form.markAsPristine();
        ngForm.form.markAsUntouched();
      }
    }));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.validate || !this.control) return;

    const modelChange: SimpleChange = changes.model;
    if (!modelChange && !this.forceValidation) return;

    this.errorMessage = '';

    if (modelChange && modelChange.firstChange == true) return;

    if (this.forceValidation || this.control.dirty || this.control.touched) {
      this.errorMessage = this.validate(this.validationProperty);
    }
  }
}
