import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter, Input, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';

import flatpickr from "flatpickr";
import { Instance } from 'flatpickr/dist/types/instance';
import { BaseOptions } from 'flatpickr/dist/types/options';
import { DatePickerSelection } from '../../models/DatePickerSelection';
import { getUtcDate, isFutureDate } from '../../utils/MomentUtils';

import * as moment from 'moment';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'shared-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements OnInit, OnChanges {
    
  // more options can be found here: https://flatpickr.js.org/options/

  @ViewChild('picker', { static: true }) myPicker: ElementRef;
  @Input() hideInput: boolean = true;
  @Input() showClearButton: boolean = true;
  @Input() pickerConfig: BaseOptions;
  @Input() disabled: boolean = false;
  @Input() disableFutureDates: boolean = true;

  @Input() singleDate: string;
  @Output() singleDateChange = new EventEmitter<string>();

  @Output() onChange = new EventEmitter<DatePickerSelection>();

  config: BaseOptions;
  placeholder: string;

  private _datePicker: Instance;
  private _selectedRange: DatePickerSelection;
  private _fireEvents: boolean;

  ngOnChanges(changes: SimpleChanges) {
    this._fireEvents = false;

    const singleDateChange = changes.singleDate;
    if (!!singleDateChange) {
      const date = singleDateChange.currentValue as Date;
      if (isNullOrUndefined(date)) {
        this._initPickerConfig(null);
      }
      else {
        this._initPickerConfig(getUtcDate(date).toISOString());
      }

      if (!date) {
        this.onClear();
      }
    }

    this._handleOnDisabledChange(changes.disabled);
    this._handleOnDisableFutureDatesChange(changes.disableFutureDates);

    this._fireEvents = true;
    this.placeholder = this.disabled ? '' : 'Select Date...';
  }

  private _handleOnDisabledChange(disabledChange: SimpleChange) {
    if (!disabledChange) return;
    this._datePicker.altInput.disabled = disabledChange.currentValue;
  }

  private _handleOnDisableFutureDatesChange(disableFutureDatesChange: SimpleChange) {
    if (!disableFutureDatesChange) return;
    let aDateIsAfterToday = false;

    // If disable future dates, check if date selected is in the future,
    // if it is, clear the date.
    if (this.disableFutureDates) {
      const today = moment.utc();
      for (let d of this._datePicker.selectedDates) {
        if (moment.utc(d).isAfter(today, 'd')) {
          aDateIsAfterToday = true;
          break;
        }
      };
    }

    if (aDateIsAfterToday) {
      this._datePicker.selectedDates = undefined;
      this._initPickerConfig(null);
    }
    else {
      const selectedDate = this._datePicker.selectedDates[0];
      if (isNullOrUndefined(selectedDate)) {
        this._initPickerConfig(null);
      } else {
        this._initPickerConfig(selectedDate.toISOString());
      }
    }
  }

  ngOnInit() {
  }

  private _initPickerConfig(date?: string) {
    this.config = Object.assign({
      mode: 'single', // can be used as 'range' | 'multiple'
      dateFormat: 'Z',
      altInput: true,
      altFormat: 'd/m/Y',
      defaultDate: date,
      disable: [
        d => {
          if (this.disableFutureDates) return isFutureDate(d);
          return false;
        }
      ],
      locale: {
        firstDayOfWeek: 1
      },
      onChange: this.onDateSelected.bind(this)
    }, this.pickerConfig);

    this._datePicker = flatpickr(this.myPicker.nativeElement, this.config);
    this.myPicker.nativeElement.value = date;
  }

  onClear() {
    // emit original value if undefined/null (otherwise it triggers a "value changed after checked" exception in console)
    if (!this.singleDate)
      this.singleDateChange.emit(this.singleDate);
    else {
      this.singleDateChange.emit('');
    }

    this._datePicker.altInput.value = '';
    this._selectedRange = undefined;
    this.onChange.emit();
  }

  onDateSelected(selectedDates, _, instance: Instance) {
    this._selectedRange = new DatePickerSelection(getUtcDate(selectedDates[0]), undefined);
    if (this.config.mode !== 'single') this._selectedRange.end = getUtcDate(selectedDates[1]);
    this._selectedRange.instance = instance;

    if (this._fireEvents) {
      this.onChange.emit(this._selectedRange);
      this.singleDateChange.emit(this._selectedRange.start.toISOString());
    }
  }
}