import { NavMenuItem } from './NavMenuItem';

import { UserLevels } from '../../../models/user/UserLevel';

export const appMenuItems: NavMenuItem[] = [
  {
    name: 'Settings', icon: 'fa-cog',
    children: [
      { name: 'App Settings', route: { path: 'app-settings', data: { minUserLevel: UserLevels.Administrator } }, icon: 'fa-wrench' },
      { name: 'User Management', route: { path: 'user-management', data: { minUserLevel: UserLevels.Administrator } }, icon: 'fa-users' }
    ]
  },
];

export const structuralTopsidesMenuItems: NavMenuItem[] = [
  { name: 'Dashboard', route: { path: 'dashboard' }, icon: 'fa-th-large' },
  { name: 'Systems', route: { path: 'systems' }, icon: 'fa-sitemap' },
  { name: 'Peer Reviews', route: { path: 'peer-reviews' }, icon: 'fa-users' },
  { name: 'Approvals', route: { path: 'approvals', data: { minUserLevel: UserLevels.AssetApprover } }, icon: 'fa-check' },
  { name: 'Interface', route: { path: 'interface', data: { minUserLevel: UserLevels.FullContributor } }, icon: 'fas fa-file-import' },
  {
    name: 'Reporting', icon: 'fa-copy',
    children: [
      { name: 'Asset Reporting', route: { path: 'reporting/asset-reporting' }, icon: 'fa-file-alt' },
      { name: 'Assessment Plan', route: { path: 'reporting/assessment-plan' }, icon: 'fa-calendar' },
      { name: 'CMMS Alignment', route: { path: 'reporting/cmms-alignment' }, icon: 'fa-align-justify' }
    ]
  }
];

export const caissonsMenuItems: NavMenuItem[] = [
  { name: 'Dashboard', route: { path: 'dashboard' }, icon: 'fa-th-large' },
  { name: 'Assessments', route: { path: 'assessments' }, icon: 'fa-book' },
  { name: 'Asset Plans', route: { path: 'asset-plans' }, icon: 'fa-th' },
  { name: 'Peer Reviews', route: { path: 'peer-reviews' }, icon: 'fa-users' },
  { name: 'Approvals', route: { path: 'approvals', data: { minUserLevel: UserLevels.AssetApprover } }, icon: 'fa-check' },
  { name: 'Interface', route: { path: 'interface', data: { minUserLevel: UserLevels.FullContributor } }, icon: 'fas fa-file-import' },
  {
    name: 'Reporting', icon: 'fa-copy',
    children: [
      { name: 'Asset Reporting', route: { path: 'reporting/asset-reporting' }, icon: 'fa-file-alt' },
      { name: 'CMMS Alignment', route: { path: 'reporting/cmms-alignment' }, icon: 'fa-align-justify' }
    ]
  }
];