import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { takeUntil, finalize } from 'rxjs/operators';

import { FooterService } from '../../../services/footer.service';
import { DialogService } from '../../../shared/services/dialog.service';
import { AppSettingsService } from '../../../services/app-settings.service';

import { AppSetting, AppSettingKeys } from '../../../models/settings/AppSettings';

import { ValidationService, ValidationTypes } from '../../../shared/services/validation.service';

import { GridComponentBase } from '../../../shared/components/base/GridComponent';

import { getModalOptions, clone } from '../../../shared/utils/Utils';

@Component({
  selector: 'orbit-app-settings',
  templateUrl: './app-settings.component.html',
  styleUrls: ['./app-settings.component.scss']
})
export class AppSettingsComponent extends GridComponentBase<AppSetting> implements OnInit {
  constructor(
    private footerService: FooterService,
    private dialogService: DialogService,
    private settingsService: AppSettingsService,
    private validationService: ValidationService,
  ) {
    super();

    this.addColumnDefs(
      [
        { field: 'key', headerName: 'Setting', minWidth: 100, width: 100, sort: 'asc' },
        { field: 'description', headerName: 'Description', autoHeight: true, minWidth: 200 },
        { field: 'value', headerName: 'Value', autoHeight: true, minWidth: 100, width: 100 }
      ]
    );

    this.footerService.showEditButton = true;
    this.footerService.onEditHandler = this._onEdit.bind(this);

    this.onDestroy = () => {
      this.footerService.clearFooter();
    };
  }

  //#region Fields

  @ViewChild('settingsModal', { static: false }) settingsModal: ModalDirective;
  modalConfig = getModalOptions();
  validationType: ValidationTypes;
  isValidating = false;

  //#endregion

  //#region Init

  ngOnInit() {
    this.startLoader();

    this.settingsService.getSettings()
      .pipe(takeUntil(this.ngUnsubscribe), finalize(() => this.stopLoader()))
      .subscribe(data => {
        this.data = data;
        this.stopLoader();
      });
  }

  //#endregion

  //#region Event Handlers

  onRowSelected($event: any) {
    if (!$event.node.isSelected()) return;
    this.currentSelection = clone($event.data);
    this.currentSelectionIdx = this.data.indexOf(this.data.find(s => s.key == this.currentSelection.key));
    this.validationType = this._getValidationType(this.currentSelection.key);

    this.footerService.updateSelectedItem(this.currentSelection);
  }

  onRowDoubleClicked(_: any) {
    this._onEdit();
  }

  private _onEdit() {
    this.isValidating = false;
    this.setCurrentSelection();

    this.settingsModal.toggle();
  }

  private _getValidationType(key: string): ValidationTypes {
    switch (key) {
      case AppSettingKeys.StEditTimeout:
        return ValidationTypes.Int;
      default:
        return ValidationTypes.Any;
    }
  }

  cancelChanges() {
    this.currentSelection = null;
    this.settingsModal.hide();
  }

  saveChanges() {
    this.isValidating = true;
    if (!this.isModelValid()) return;
    this.isValidating = false;

    this.dialogService.showLoadingDialog('Saving Changes');

    this.settingsService.editSetting(this.currentSelection)
      .pipe(takeUntil(this.ngUnsubscribe), finalize(() => this.settingsModal.hide()))
      .subscribe(data => {
        this.data[this.currentSelectionIdx] = data;
        this.updateGridAfterEdit(this.currentSelection);

        this.dialogService.showDialog('Success!', 'App setting updated successfully.', 'success');
      });
  }

  //#endregion

  //#region Validation

  isModelValid(): boolean {
    switch (this.validationType) {
      case ValidationTypes.Int:
        return this.validationService.isIntegerValid(this.currentSelection.value, true, 10);
      default:
        return !!this.currentSelection.value;
    }
  }

  validateModel(prop: string): string {
    switch (this.validationType) {
      case ValidationTypes.Int:
        return this.validationService.validateInteger(this.currentSelection[prop], true, 10);
      default:
        return this.validationService.validateRequired(this.currentSelection[prop]);
    }
  }

  //#endregion
}