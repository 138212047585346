import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { GridTooltipHandler, TooltipData } from '../../../utils/GridTooltipHandler';

@Component({
  selector: 'shared-grid-tooltip',
  templateUrl: './grid-tooltip.component.html',
  styleUrls: ['./grid-tooltip.component.scss']
})
export class GridTooltipComponent implements OnDestroy {

  tooltipData: TooltipData;
  style = {};

  private _tooltipSubscription: Subscription;

  constructor() {
    this._tooltipSubscription = GridTooltipHandler.getTooltipData()
      .subscribe(data => {
        if (data.hide) {
          this.tooltipData = undefined;
        }
        else {
          this.tooltipData = data;

          const rect = data.srcElement.getBoundingClientRect();
          this.style = { 'top': `${rect.top}px`, 'left': `${rect.left}px`, 'margin-top': `${rect.height}px` };
        }
      });
  }

  ngOnDestroy(): void {
    if (this._tooltipSubscription)
      this._tooltipSubscription.unsubscribe();
  }

}
