import { Component } from '@angular/core';
import { Router, RouteConfigLoadEnd, NavigationEnd, NavigationCancel, NavigationError, ActivationStart } from '@angular/router';
import { filter } from 'rxjs/operators';
import { SelfUnsubscriberBase } from './shared/components/base/SelfUnsubscriber';

@Component({
  selector: 'orbit-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends SelfUnsubscriberBase {
  public isNavigating = false;

  constructor(
    private router: Router
  ) {
    super();

    this.subscriptions.push(this.router.events.pipe(filter(e => e instanceof ActivationStart))
      .subscribe(_ => {
        this.isNavigating = true;
      }));

    this.subscriptions.push(this.router.events.pipe(filter(e => e instanceof RouteConfigLoadEnd
      || e instanceof NavigationEnd
      || e instanceof NavigationCancel
      || e instanceof NavigationError))
      .subscribe(_ => {
        this.isNavigating = false;
      }));
  }
}
