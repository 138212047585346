<div class="card card-standard m-b-none">
  <div class="card-body">
    <label *ngIf="title">{{title}}</label>
    <div class="col-lg-12 no-padding">
      <label for="file" class="file-upload">
        <button class="btn btn-primary btn-block"><i class="fas fa-folder-open"></i>&nbsp; Select File</button>
        <input id="file" #fileInput type="file" ng2FileSelect [uploader]="uploader" (change)="onChange()"
               (onFileSelected)="onFileDrop($event)" [accept]="mimeType" />
      </label>
    </div>
    <div class="text-center text-muted m-b-xs">OR</div>
    <div class="col-lg-12 no-padding">
      <div ng2FileDrop [ngClass]="{'nv-file-over': hasDropZoneOver}" (fileOver)="fileOverBase($event)" [uploader]="uploader"
           class="box-placeholder my-drop-zone text-center" (onFileDrop)="onFileDrop($event)">
        <div class="text-muted"> Drop to upload </div>
      </div>
    </div>
  </div>
  <div class="col-lg-12 file-details" *ngIf="selectedFile">
    <strong class="col-3">{{selectedFile.name}}</strong>&nbsp;&nbsp;&nbsp;
    <span class="col-2">{{selectedFile.size/1024/1024 | number:'.2'}} MB</span>
    <i class="col-6 text-muted replace-file-info">To replace the file, simply select a different one...</i>
  </div>
  <div class="col-lg-12" *ngIf="errors">
    <strong *ngFor="let err of errors" class="col-lg-12 form-error">
      {{err}}
    </strong>
  </div>
</div>