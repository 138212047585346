import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize, takeUntil, catchError } from 'rxjs/operators';

import { UserService } from '../../../services/user.service';
import { DialogService } from '../../../shared/services/dialog.service';
import { ValidationService } from '../../../shared/services/validation.service';

import { LoadableComponentBase } from '../../../shared/components/base/LoadableComponent';

import { PasswordSetupData } from '../../../models/user/PasswordSetup';

import { getErrorMessage } from '../../../shared/utils/Utils';

@Component({
  selector: 'orbit-password-setup',
  templateUrl: './password-setup.component.html',
  styleUrls: ['./password-setup.component.scss']
})
export class PasswordSetupComponent extends LoadableComponentBase implements OnInit {

  constructor(
    private validationService: ValidationService,
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    private dialogService: DialogService,
  ) {
    super();
  }

  data: PasswordSetupData;
  showLoginBox: boolean = true;
  isValidating: boolean = false;

  ngOnInit() {
    this.data = new PasswordSetupData();
    this.data.token = this.route.snapshot.params.token;
  }

  onSubmit() {
    this.isValidating = true;
    if (!this.isModelValid()) return;
    this.isValidating = false;

    this.startLoader();

    this.userService.setupPassword(this.data)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        finalize(() => this.stopLoader()),
        catchError(err => this.dialogService.showError('An unexpected error has occurred!', getErrorMessage(err)))
      )
      .subscribe(_ => {
        this.showLoginBox = false;
        this.dialogService.showDialog('Success!', 'Account set up successfully.', 'success')
          .then(_ => {
            this.router.navigate(['/login']);
          });
      });
  }

  isModelValid(): boolean {
    return !!this.data
      && !!this.data.email
      && !!this.data.newPassword
      && !!this.data.confirmPassword
      && this.data.newPassword == this.data.confirmPassword
      && !this.validationService.validatePasswordStrength(this.data.newPassword);
  }

  get modelValidator() {
    return this.validateModel.bind(this);
  }

  validateModel(prop: string): string {
    switch (prop) {
      case 'email':
        return this.validationService.validateEmail(this.data.email, true);
      case 'newPassword':
        return this.validationService.validatePasswordStrength(this.data.newPassword);
      case 'confirmPassword':
        const reqError = this.validationService.validateRequired(this.data[prop]);
        if (!!reqError) return reqError;
        return this.validationService.validatePasswordConfirmation(this.data.newPassword, this.data.confirmPassword);
      default:
        return this.validationService.validateRequired(this.data[prop]);
    }
  }
}