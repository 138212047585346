import { Injectable, TemplateRef } from '@angular/core';
import { ReplaySubject, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FooterService {

  constructor() { }

  events = new ReplaySubject<FooterEvent>(1);

  injectView(template: TemplateRef<any>, persistent?: boolean): Subscription {
    this.events.next({
      template, type: 'create', options: { persistent: persistent }
    });

    return new Subscription(() => {
      this.events.next({
        template, type: 'destroy', options: { persistent: persistent }
      });
    });
  }

  public onAddHandler: () => unknown;
  public onViewHandler: () => unknown;
  public onEditHandler: () => unknown;
  public onDeleteHandler: () => unknown;

  public showAddButton: boolean = false;
  public showViewButton: boolean = false;
  public showEditButton: boolean = false;
  public showDeleteButton: boolean = false;

  public isItemSelected: unknown;

  public onAdd() {
    if (!!this.onAddHandler)
      this.onAddHandler();
  }

  public onView() {
    if (!!this.onViewHandler)
      this.onViewHandler();
  }

  public onEdit() {
    if (!!this.onEditHandler)
      this.onEditHandler();
  }

  public onDelete() {
    if (!!this.onDeleteHandler)
      this.onDeleteHandler();
  }

  public updateSelectedItem(data: unknown) {
    this.isItemSelected = data;
  }

  public clearFooter() {
    this.showAddButton = false;
    this.showDeleteButton = false;
    this.showEditButton = false;
    this.showViewButton = false;

    this.onAddHandler = null;
    this.onViewHandler = null;
    this.onEditHandler = null;
    this.onDeleteHandler = null;

    this.isItemSelected = null;
  }
}

export class FooterEvent {
  template: TemplateRef<any>;
  type: 'create' | 'destroy';
  options?: {
    persistent: boolean
  }
}