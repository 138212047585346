import { HttpErrorResponse } from '@angular/common/http';
import { ModalOptions } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { Constants } from './Constants';

export function clone(data) { return data != null ? JSON.parse(JSON.stringify(data)) : data; }

export function getErrorMessage(e, entityValidationFormatter?: (any) => string, errorPrefix?: string, errorSuffix?: string): string {
  const prefix = errorPrefix ? `${errorPrefix}\n` : '';
  const suffix = errorSuffix ? `\n${errorSuffix}` : '';

  if (!e) {
    return `${prefix}${e}${suffix}`;
  }
  if (e.error instanceof ProgressEvent) {
    return `${prefix}${e.message}${suffix}`;
  }

  if (e.error) {
    e = e.error;
    if (entityValidationFormatter && e.errors) {
      return `\n${e.title}\n${entityValidationFormatter(e.errors)}${suffix}`;
    }
    if (e.exceptionMessage) {
      return `${prefix}${e.exceptionMessage}${suffix}`;
    } else if (e.message) {
      return `${prefix}${e.message}${suffix}`;
    } else if (e.errors) {
      return `${prefix}${getValidationErrors(e.errors)}${suffix}`;
    } else {
      return `${prefix}${e}${suffix}`;
    }
  } else if (e._error) {
    return `${prefix}${e._error}${suffix}`;
  }

  if (e instanceof HttpErrorResponse) {
    return `${prefix}${e.error ? e.error : e.message}${suffix}`;
  }

  return `${prefix}${e}${suffix}`;
}

export function getValidationErrors(validationErrors: any): string {
  let errors: string = '<ul style="text-align: left; margin-top: 10px;">';

  for (let entityName in validationErrors) {
    if (validationErrors.hasOwnProperty(entityName)) {
      errors += `${validationErrors[entityName].map(ve => `<li><b>${entityName}</b>: ${ve}</li>`).join('')}`;
    }
  }

  return `${errors}</ul>`;
}

export function getCopyrightYears(): string {
  return _getCopyrightYears(Constants.StartYear);
}

export function _getCopyrightYears(startYear: number): string {
  let year = new Date();
  let currentYear: number = year.getUTCFullYear();
  if (currentYear == startYear) return `${startYear}`;
  return `${startYear}-${currentYear}`;
}

export function getModalOptions(): ModalOptions {
  return {
    backdrop: 'static',
    keyboard: false,
    ignoreBackdropClick: true,
  }
}

export const modalHideEvent: Subject<any> = new Subject();

export function stringify(o): string {
  let cache = [];
  let result = JSON.stringify(o, function (_, value) {
    if (typeof value === 'object' && value !== null) {
      if (cache.indexOf(value) !== -1) {
        // Duplicate reference found
        try {
          // If this value does not reference a parent it can be deduped
          return JSON.parse(JSON.stringify(value));
        } catch (error) {
          // discard key if value cannot be deduped
          return;
        }
      }
      // Store value in our collection
      cache.push(value);
    }
    return value;
  });
  cache = null;

  return result;
}