import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SessionInfoService } from '../shared/services/session-info.service';

@Injectable()
export class HttpSessionInterceptor implements HttpInterceptor {
  constructor(
    private sessionInfoService: SessionInfoService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let headers = {
      setHeaders: {
        'x-orbit-sessionid': this.sessionInfoService.getSessionId()
      }
    };

    if (this.isIE()) {
      headers.setHeaders['Cache-Control'] = 'no-cache';
      headers.setHeaders['Pragma'] = 'no-cache';
    }

    request = request.clone(headers);
    return next.handle(request);
  }

  private isIE() {
    return navigator.userAgent.search(/(?:Edge|MSIE|Trident\/.*; rv:)/) !== -1;
  }
}