import { Injectable } from '@angular/core';
import { SharedModule } from '../shared.module';
import * as moment from 'moment';
import { isNullOrUndefined } from 'util';

const EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const PASSWORD_REGEXP = '^(?=.*[A-Z].)(?=.*[a-z])(?=.*[0-9])(?=.*[`¬!"£$%^&*()\\-_=+{};\'#:@~,.<>\?\/|\[\\]\\\\]).{8,}$';

@Injectable({
  providedIn: SharedModule
})
export class ValidationService {

  public validateRequired(value: any): string {
    return (!isNullOrUndefined(value) && value !== '') ? '' : 'Value cannot be empty';
  }

  public isNumberValid(value: any, isRequired: boolean = false, min?: number, max?: number): boolean {
    if (isNullOrUndefined(value) || value === '') return !isRequired;

    const numberValue = Number(value);

    if (min != undefined && numberValue < min) return false;
    if (max != undefined && numberValue > max) return false;

    return numberValue == value;
  }

  public validateNumber(value: any, isRequired: boolean = false, min?: number, max?: number): string {
    if (isNullOrUndefined(value) || value === '') return isRequired ? 'Value cannot be empty' : '';

    const numberValue = Number(value);

    if (min != undefined && numberValue < min) return `Value cannot be less than ${min}`;
    if (max != undefined && numberValue > max) return `Value cannot be greater than ${max}`;

    return numberValue == value ? '' : 'Specified value is not a valid number';
  }

  public isIntegerValid(value: any, isRequired: boolean = false, min?: number, max?: number): boolean {
    if (!this.isNumberValid(value, isRequired, min, max)) return false;
    if (!isRequired && !value) return true;

    const intValue = Number(value);
    if ((Number(value) != value) || (intValue % 1 !== 0)) return false;
    if (`${value}`.indexOf('.') >= 0) return false;

    return true;
  }

  public validateInteger(value: any, isRequired: boolean = false, min?: number, max?: number): string {
    let numValidation = this.validateNumber(value, isRequired, min, max);
    if (numValidation) return numValidation;

    return this.isIntegerValid(value, isRequired, min, max) ? '' : 'Specified value is not a valid integer';
  }

  public isPositiveNumberValid(value: any, isRequired: boolean = false): boolean {
    if (!this.isNumberValid(value, isRequired)) return false;

    return value > 0;
  }

  public validatePositiveNumber(value: any, isRequired: boolean = false): string {
    const validationMessage = this.validateNumber(value, isRequired);
    if (!!validationMessage) return validationMessage;

    return value <= 0 ? 'Value cannot be 0 or less' : '';
  }
  public isDateValid(dateValue: string, isRequired: boolean = false): boolean {
    if (isNullOrUndefined(dateValue) && !isRequired) return true;
    return moment(dateValue, moment.ISO_8601).isValid();
  }

  public validateDate(dateValue: string, isRequired: boolean = false): string {
    if (isNullOrUndefined(dateValue)) return isRequired ? 'Date must be selected' : '';

    return this.isDateValid(dateValue) ? '' : 'Specified date is invalid';
  }

  public validateEmail(value: string, isRequired: boolean = false): string {
    if (isNullOrUndefined(value)) return isRequired ? 'Value cannot be empty' : '';

    const emailCheck = RegExp(EMAIL_REGEXP);
    return emailCheck.test(value) ? '' : 'Specified email is not valid';
  }

  public validatePasswordConfirmation(password: string, confirmPassword: string): string {
    return password == confirmPassword ? '' : 'The two password inputs do not match';
  }

  public validatePasswordStrength(password: string): string {
    const regxp = new RegExp(PASSWORD_REGEXP);
    return regxp.test(password) ? '' : 'Password must contain at least 8 characters, including UPPER and lower letters, a number and a symbol.';
  }

  public isLinkValid(value: string, isRequired: boolean = false): boolean {
    if (!value) return !isRequired;

    return (value.toLowerCase().indexOf('http') == 0) && (value.indexOf('://') > 0);
  }

  public validateLink(value: string, isRequired: boolean = false): string {
    if (!value) return isRequired ? 'Value cannot be empty' : '';

    return this.isLinkValid(value) ? '' : 'Specified link is not valid';
  }
}

export enum ValidationTypes {
  Any,
  Int,
  Double,
  Date
}
