import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { JwtModule } from '@auth0/angular-jwt';
import { CookieService } from 'ngx-cookie-service';

import { AppComponent } from './app.component';

import { LayoutComponent } from './components/layout/layout.component';
import { HeaderComponent } from './components/layout/header/header.component';
import { SidenavComponent } from './components/layout/sidenav/sidenav.component';
import { FooterComponent } from './components/layout/footer/footer.component';
import { NotFoundComponent } from './components/layout/not-found/not-found.component';
import { LoginComponent } from './components/account/login/login.component';
import { ResetPasswordComponent } from './components/account/reset-password/reset-password.component';
import { PasswordSetupComponent } from './components/account/password-setup/password-setup.component';
import { HomeComponent } from './components/home/home.component';
import { AppSettingsComponent } from './components/settings/app-settings/app-settings.component';
import { UserManagementComponent } from './components/settings/user-management/user-management.component';

// Providers
import { HttpSessionInterceptor } from './interceptors/http-session.interceptor';
import { AuthGuard } from './guards/auth.guard';
import { AccessGuard } from './guards/access.guard';

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    HeaderComponent,
    SidenavComponent,
    FooterComponent,
    NotFoundComponent,
    LoginComponent,
    ResetPasswordComponent,
    PasswordSetupComponent,
    HomeComponent,
    AppSettingsComponent,
    UserManagementComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    JwtModule.forRoot({ config: { tokenGetter: () => undefined, throwNoTokenError: false } }),

    SharedModule
  ],
  providers: [
    // Interceptors
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpSessionInterceptor,
      multi: true
    },
    CookieService,

    // Guards
    AuthGuard,
    AccessGuard,
  ],
  bootstrap: [AppComponent],
  entryComponents: [ResetPasswordComponent]
})
export class AppModule { }