import { NgModule, Injector } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MultiselectDropdownModule } from 'angular-2-dropdown-multiselect';
import { AgGridModule } from 'ag-grid-angular';
import { ChartsModule } from 'ng2-charts';
import { ModalModule } from 'ngx-bootstrap';
import { NouisliderModule } from 'ng2-nouislider';
import { FileUploadModule } from 'ng2-file-upload';

import { LoaderComponent } from './components/loader/loader.component';

import { DateRangeFilterComponent } from './components/ag-grid/filters/date-range-filter/date-range-filter.component';
import { SetFilterComponent } from './components/ag-grid/filters/set-filter/set-filter.component';
import { ButtonCellRendererComponent } from './components/ag-grid/renderers/button-cell-renderer/button-cell-renderer.component';
import { GridTooltipComponent } from './components/ag-grid/grid-tooltip/grid-tooltip.component';
import { GridTooltipWrapperComponent } from './components/ag-grid/grid-tooltip-wrapper/grid-tooltip-wrapper.component';

import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { InputWrapperComponent } from './components/input-wrapper/input-wrapper.component';
import { TooltipWrapperComponent } from './components/tooltip-wrapper/tooltip-wrapper.component';
import { StatusComponent } from './components/status/status.component';
import { TabbedViewComponent } from './components/tabbed-view/tabbed-view.component';
import { EditModeLockComponent } from './components/edit-mode-lock/edit-mode-lock.component';
import { EditModePanelComponent } from './components/edit-mode-panel/edit-mode-panel.component';
import { FileUploaderComponent } from './components/file-uploader/file-uploader.component';

import { LoadingService } from './services/loading.service';

import { TimespanPipe } from './pipes/timespan.pipe';
import { ShortTimespanPipe } from './pipes/short-timespan.pipe';
import { EnumToArrayPipe } from './pipes/enum-to-array.pipe';

export let AppInjector: Injector;

@NgModule({
  declarations: [
    LoaderComponent,

    DateRangeFilterComponent,
    SetFilterComponent,
    ButtonCellRendererComponent,
    GridTooltipComponent,
    GridTooltipWrapperComponent,

    DatePickerComponent,
    InputWrapperComponent,
    TooltipWrapperComponent,
    StatusComponent,
    TabbedViewComponent,
    EditModeLockComponent,
    EditModePanelComponent,
    FileUploaderComponent,

    TimespanPipe,
    ShortTimespanPipe,
    EnumToArrayPipe,
  ],
  imports: [
    RouterModule,
    CommonModule,
    FormsModule,
    MultiselectDropdownModule,
    ModalModule.forRoot(),
    ChartsModule,
    FileUploadModule,
    NouisliderModule,
    AgGridModule.withComponents([
      SetFilterComponent,
      DateRangeFilterComponent,
      ButtonCellRendererComponent,
      GridTooltipComponent,
      GridTooltipWrapperComponent
    ])
  ],
  providers: [
    LoadingService
  ],
  exports: [
    RouterModule,
    CommonModule,
    FormsModule,
    MultiselectDropdownModule,
    ModalModule,
    ChartsModule,
    NouisliderModule,
    AgGridModule,

    LoaderComponent,

    SetFilterComponent,
    DateRangeFilterComponent,
    ButtonCellRendererComponent,
    GridTooltipComponent,
    GridTooltipWrapperComponent,

    DatePickerComponent,
    InputWrapperComponent,
    TooltipWrapperComponent,
    StatusComponent,
    TabbedViewComponent,
    EditModeLockComponent,
    EditModePanelComponent,
    FileUploadModule,
    FileUploaderComponent,

    TimespanPipe,
    ShortTimespanPipe,
    EnumToArrayPipe,
  ]
})
export class SharedModule { 
  constructor(private injector: Injector) {
    AppInjector = this.injector;
  }
}
