<div class="footer fixed">
  <img class="footer-logo" src="assets/img/Client-Logo.png" alt="EnQuest Logo" draggable="false" />
  <img class="footer-logo m-l-md" src="assets/img/Kent-Logo.png" alt="Kent Logo" draggable="false" />

  <div class="footer-button-panel float-right m-r-n-sm">
    <button class="btn btn-primary btn-action-medium" (click)="footerService.onAdd()"
            *ngIf="footerService.showAddButton" [disabled]="loadingService.isDataLoadingSomewhere">
      <i class="fa fa-plus" aria-hidden="true"></i> Add
    </button>
    <button class="btn btn-secondary btn-action-medium" (click)="footerService.onView()"
            *ngIf="footerService.showViewButton" [disabled]="!footerService.isItemSelected">
      <i class="fa fa-folder-open" aria-hidden="true"></i> View
    </button>
    <button class="btn btn-secondary btn-action-medium" (click)="footerService.onEdit()" *ngIf="footerService.showEditButton"
            [disabled]="!footerService.isItemSelected">
      <i class="fa fa-edit" aria-hidden="true"></i> Edit
    </button>
    <button class="btn btn-danger btn-action-medium" (click)="footerService.onDelete()"
            *ngIf="footerService.showDeleteButton" [disabled]="!footerService.isItemSelected">
      <i class="fa fa-times" aria-hidden="true"></i> Delete
    </button>

    <ng-container #footerContainer>
    </ng-container>
  </div>
</div>