<div class="row wrapper white-bg page-heading-with-description">
  <h2 class="col-lg-4 p-s-none m-b-none">
    Application Settings
    <small class="sub-heading-small">Update the relevant application settings using the table below.</small>
  </h2>
</div>

<div class="small-break"></div>
<orbit-loader *ngIf="isLoading"></orbit-loader>

<div class="row settings scrollable" [hidden]="isLoading">
  <ag-grid-angular #agGrid [rowData]="data" class="ag-theme-balham"
                   style="height: calc(100vh - 236px); min-height: 300px" [gridOptions]="gridOptions"
                   (rowSelected)="onRowSelected($event)" (rowDoubleClicked)="onRowDoubleClicked($event)">
  </ag-grid-angular>
</div>

<div bsModal #settingsModal="bs-modal" [config]="modalConfig" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Update Application Setting</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="cancelChanges()">
          <span aria-hidden="true">&times;</span><span class="sr-only">Close</span>
        </button>
      </div>
      <form ngNativeValidate (ngSubmit)="saveChanges()" *ngIf="currentSelection">
        <div class="modal-body">
          <div class="row">
            <input-wrapper labelText="{{currentSelection.key}}:" labelClass="col-lg-4" contentClass="col-lg-8"
                           [tips]="currentSelection.description" [model]="currentSelection.value" [validate]="modelValidator"
                           validationProperty="value" [forceValidation]="isValidating" [control]="value">
              <input type="text" placeholder="Value" class="form-control" name="currentSelection.value"
                     [(ngModel)]="currentSelection.value" #value="ngModel">
            </input-wrapper>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-white btn-action" (click)="cancelChanges()">Close</button>
          <input type="submit" class="btn btn-primary btn-action" value="Save">
        </div>
      </form>
    </div>
  </div>
</div>