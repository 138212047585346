import { Component, Input, Output, EventEmitter } from '@angular/core';
import { EditModeDetails } from '../../models/EditModeDetails';

@Component({
  selector: 'shared-edit-mode-panel',
  templateUrl: './edit-mode-panel.component.html',
  styleUrls: ['./edit-mode-panel.component.scss']
})
export class EditModePanelComponent {

  constructor() { }

  @Input() isInEditMode: boolean;
  @Input() canEdit: boolean;
  @Input() editModeDetails: EditModeDetails;
  @Input() disabled: boolean;


  @Output() onEdit = new EventEmitter<void>();
  @Output() onEditCancel = new EventEmitter<void>();

  onEditCancelClick() {
    this.onEditCancel.emit();
  }

  onEditClick() {
    this.onEdit.emit();
  }
}
