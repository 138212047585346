export class UserLevel {
  id: number;
  name: string;
}

export class UserLevels {
  public static readonly ReadOnly: number = 1;
  public static readonly PartialContributor: number = 2;
  public static readonly FullContributor: number = 3;
  public static readonly AssetApprover: number = 4;
  public static readonly Administrator: number = 5;
}