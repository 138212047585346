import { SelfUnsubscriberBase } from './SelfUnsubscriber';
import { LoadingService } from '../../services/loading.service';
import { AppInjector } from '../../shared.module';

export abstract class LoadableComponentBase extends SelfUnsubscriberBase {
  private _isLoading: boolean = false;
  public loadingService: LoadingService;

  constructor() {
    super();
    this.loadingService = AppInjector.get(LoadingService);
  }

  public get isLoading() {
    return this._isLoading;
  }

  protected startLoader() {
    this._isLoading = true;
    this.loadingService.isDataLoadingSomewhere = true;
  }

  protected stopLoader() {
    this._isLoading = false;
    this.loadingService.isDataLoadingSomewhere = false;
  }
}