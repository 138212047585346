<orbit-loader *ngIf="isLoading"></orbit-loader>

<div class="login-box" *ngIf="!isLoading && showLoginBox">
  <div class="logo-container">
    <img class="img-fluid" src="assets/img/App-Logo.png" alt="ORBiT Logo" draggable="false" />
  </div>

  <h2 class="m-b-xs">Account Password Setup</h2>

  <form class="form-horizontal" (ngSubmit)="onSubmit()">
    <div class="form-group">
      <input-wrapper labelText="" labelClass="hidden" contentClass="col-lg-12"
                     [model]="data.email" [validate]="modelValidator" validationProperty="email"
                     [forceValidation]="isValidating" [control]="email">
        <input type="email" placeholder="Email" class="form-control" name="data.email"
               [(ngModel)]="data.email" #email="ngModel" [disabled]="isLoading" />
      </input-wrapper>
    </div>

    <div class="form-group">
      <input-wrapper class="input-container" labelText="" labelClass="hidden" contentClass="col-lg-12"
                     [model]="data.newPassword" [validate]="modelValidator" validationProperty="newPassword"
                     [forceValidation]="isValidating" [control]="newPassword">
        <input type="password" placeholder="Password" class="form-control" name="data.newPassword"
               [(ngModel)]="data.newPassword" #newPassword="ngModel" [disabled]="isLoading" />
      </input-wrapper>
    </div>

    <div class="form-group">
      <input-wrapper class="input-container" labelText="" labelClass="hidden" contentClass="col-lg-12"
                     [model]="data.confirmPassword" [validate]="modelValidator" validationProperty="confirmPassword"
                     [forceValidation]="isValidating" [control]="confirmPassword">
        <input type="password" placeholder="Confirm password" class="form-control" name="data.confirmPassword"
               [(ngModel)]="data.confirmPassword" #confirmPassword="ngModel" [disabled]="isLoading" />
      </input-wrapper>
    </div>
    <div class="form-group m-b-none text-center" style="display: flex; flex-direction: row; justify-content: center;">
      <div class="col-lg-4">
        <button [disabled]="isLoading" class="btn btn-password btn-secondary">
          <i class="fas fa-sign-in-alt" aria-hidden="true"></i> Submit
        </button>
      </div>
    </div>
  </form>
</div>
