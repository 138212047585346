import { Component, ViewChild, Input, ElementRef, Output, EventEmitter } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import { Constants } from '../../../shared/utils/Constants';

@Component({
  selector: 'shared-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss']
})
export class FileUploaderComponent {

  @Input() title: string;
  @Input() mimeType: string = '';
  @Input() maxFileSize: number = 0;
  @Output() onFileSelected = new EventEmitter<File>();

  @ViewChild('fileInput') fileInput: ElementRef;

  selectedFile: File;
  hasDropZoneOver: boolean = false;
  uploader: FileUploader = new FileUploader({
    allowedMimeType: [this.mimeType],
    maxFileSize: this.maxFileSize
  });
  errors: string[] = [];


  public onChange() {
    this.fileInput.nativeElement.value = '';
  }

  public fileOverBase(e: any): void {
    this.hasDropZoneOver = e;
  }

  public onFileDrop(e) {
    if (e.length == 0) return;
    this.selectedFile = e[0];
    this.errors = [];

    switch (this.mimeType) {
      case Constants.MimeTypes.IMAGES:
        if (!this.selectedFile.type.includes('image')) {
          this.errors.push('Invalid file type');
        }
        break;;
      case Constants.MimeTypes.EXCEL:
      case Constants.MimeTypes.FULL_EXCEL:
        if (!['xlsm', 'xlsx'].includes(this.selectedFile.name.split('.').pop())) {
          this.errors.push('Invalid file type');
        }
        break;
      default:
        break;
    }

    if (this.selectedFile.size > this.maxFileSize) {
      this.errors.push(`File is too big. Maximum file size is: ${this.maxFileSize / 1024 / 1024} MB`);
    }

    if (this.errors.length > 0) {
      this.selectedFile = null;
    }

    this.onFileSelected.emit(this.selectedFile);
  }
}
