import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil, finalize, catchError } from 'rxjs/operators';

import { UserService } from '../../../services/user.service';
import { DialogService } from '../../../shared/services/dialog.service';
import { ValidationService } from '../../../shared/services/validation.service';

import { LoadableComponentBase } from '../../../shared/components/base/LoadableComponent';

import { Credentials } from '../../../models/user/Credentials';

import { getErrorMessage } from '../../../shared/utils/Utils';

@Component({
  selector: 'orbit-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends LoadableComponentBase implements OnInit {

  returnUrl: string;
  data: Credentials = new Credentials();
  isValidating: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private dialogService: DialogService,
    private validationService: ValidationService
  ) {
    super();

    // redirect to home if already logged in
    if (this.userService.getUser()) {
      this.router.navigate(['']);
    }
  }

  ngOnInit() {
    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  onSubmit() {
    this.isValidating = true;
    if (!this.isModelValid()) return;
    this.isValidating = false;

    this.startLoader();

    this.userService.login(this.data)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        finalize(() => this.stopLoader()),
        catchError(err => this.dialogService.showError("An unexpected error has occurred!", getErrorMessage(err))))
      .subscribe(() => {
        this.router.navigate(['/']);
      });
  }

  isModelValid(): boolean {
    return !!this.data
      && !!this.data.email
      && !!this.data.password;
  }

  get modelValidator() {
    return this.validateModel.bind(this);
  }

  validateModel(prop: string): string {
    switch (prop) {
      case 'email':
        return this.validationService.validateEmail(this.data.email, true);
      default:
        return this.validationService.validateRequired(this.data[prop]);
    }
  }
}