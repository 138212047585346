export class AppSetting {
  key: string;
  description: string;
  value: string;
}

export class AppSettingKeys {
  public static readonly StEditTimeout: string = 'Structural Topsides Assessment Edit Timeout';
  public static readonly StDocumentRepositoryFolder: string = 'Structural Topsides Document Repository Folder';
  public static readonly StGeneratedDocumentsFolder: string = 'Structural Topsides Generated Documents Folder';
}