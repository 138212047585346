<div class="sidenav-wrapper">
  <nav class="navbar-default navbar-static-side " [ngClass]="isSidebarCollapsed ? 'mini-navbar' : ''" role="navigation">
    <ul class="nav metismenu sidebar-nav" id="side-menu">
      <li [class.active]="isMenuItemActive(homeMenuItem)">
        <a [routerLink]="[homeMenuItem.route.path]">
          <i class="fa" [ngClass]="homeMenuItem.icon ? homeMenuItem.icon : ''"></i>
          <span class="nav-label">{{homeMenuItem.name}}</span>
        </a>
      </li>
      <li *ngFor="let menuItem of menuItems" [class.active]="isMenuItemActive(menuItem)">
        <ng-container *ngIf="menuItem.route">
          <a [routerLink]="[menuItem.route.path]">
            <i class="fa" [ngClass]="menuItem.icon ? menuItem.icon : ''"></i>
            <span class="nav-label">{{menuItem.name}}</span>
          </a>
        </ng-container>
        <ng-container *ngIf="!menuItem.route">
          <a (click)="toggleMenuItem(menuItem)">
            <i class="fa" [ngClass]="menuItem.icon ? menuItem.icon : ''"></i>
            <span class="nav-label">{{menuItem.name}}</span>
            <span class="fa" style="float:right;" *ngIf="menuItem.children"
                  [ngClass]="{'fa-angle-left': !isMenuItemToggled(menuItem), 'fa-angle-down': isMenuItemToggled(menuItem)}"></span>
          </a>
          <ul class="nav nav-second-level sidebar-nav sidebar-subnav" *ngIf="menuItem.children"
              [class.collapse]="!isMenuItemToggled(menuItem)">
            <li *ngFor="let childMenuItem of menuItem.children" [class.active]="isMenuItemActive(childMenuItem)">
              <a [routerLink]="[childMenuItem.route.path]">
                <i class="fa" [ngClass]="childMenuItem.icon ? childMenuItem.icon : ''"></i> {{childMenuItem.name}}
              </a>
            </li>
          </ul>
        </ng-container>
      </li>
    </ul>
    <div class="copyright-text copyright copyright-small">
      ORBiT
    </div>
    <div class="copyright-text copyright copyright-large">
      ORBiT © {{copyrightYears}}
    </div>
  </nav>
</div>