<orbit-loader *ngIf="isLoading"></orbit-loader>

<div class="login-box" *ngIf="!isLoading">
  <div class="logo-container">
    <img class="img-fluid" src="assets/img/App-Logo.png" alt="ORBiT Logo" draggable="false" />
  </div>

  <form class="form-horizontal" (ngSubmit)="onSubmit()">
    <div class="form-group">
      <input-wrapper labelText="" labelClass="hidden" contentClass="col-lg-12"
                     [model]="data.oldPassword" [validate]="modelValidator" validationProperty="oldPassword"
                     [forceValidation]="isValidating" [control]="oldPassword">
        <input type="password" placeholder="Old Password" class="form-control" name="data.oldPassword"
               [(ngModel)]="data.oldPassword" #oldPassword="ngModel" [disabled]="isLoading" />
      </input-wrapper>
    </div>

    <div class="form-group">
      <input-wrapper labelText="" labelClass="hidden" contentClass="col-lg-12"
                     [model]="data.newPassword" [validate]="modelValidator" validationProperty="newPassword"
                     [forceValidation]="isValidating" [control]="newPassword">
        <input type="password" placeholder="New Password" class="form-control" name="data.newPassword"
               [(ngModel)]="data.newPassword" #newPassword="ngModel" [disabled]="isLoading" />
      </input-wrapper>
    </div>

    <div class="form-group">
      <input-wrapper labelText="" labelClass="hidden" contentClass="col-lg-12"
                     [model]="data.confirmPassword" [validate]="modelValidator" validationProperty="confirmPassword"
                     [forceValidation]="isValidating" [control]="confirmPassword">
        <input type="password" placeholder="Confirm Password" class="form-control" name="data.confirmPassword"
               [(ngModel)]="data.confirmPassword" #confirmPassword="ngModel" [disabled]="isLoading" />
      </input-wrapper>
    </div>
    <div class="form-group m-b-none text-center">
      <button [disabled]="isLoading" class="btn btn-action-large btn-secondary m-r-sm">
        <i class="fas fa-sign-in-alt" aria-hidden="true"></i> Submit
      </button>
      <button type="button" class="btn btn-action-large btn-info m-l-sm" (click)="close()">
        <i class="fas fa-times" aria-hidden="true"></i> Cancel
      </button>
    </div>
  </form>
</div>