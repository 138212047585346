import * as moment from 'moment';

export function getUtcDate(d: Date): moment.Moment {
  const m = moment(d);
  return moment.utc(m.add(m.utcOffset(), 'm'));
}

export function getUtcDateFromString(d: string): moment.Moment {
  const m = moment(d);
  return moment.utc(m.add(m.utcOffset(), 'm'));
}

export function isSameDate(date1: string, date2: string): boolean {
  const firstDate = getUtcDateFromString(date1);
  const secondDate = getUtcDateFromString(date2);

  return firstDate.isSame(secondDate, 'd');
}

export function isSameOrAfterDate(date1: string, date2: string): boolean {
  const firstDate = getUtcDateFromString(date1);
  const secondDate = getUtcDateFromString(date2);

  return firstDate.isSameOrAfter(secondDate, 'd');
}

export function isAfterDate(date1: string, date2: string): boolean {
  const firstDate = getUtcDateFromString(date1);
  const secondDate = getUtcDateFromString(date2);

  return firstDate.isAfter(secondDate, 'd');
}

export function isFutureDate(date: string): boolean {
  const now = moment.utc();
  const utcToday = moment.utc(now.add(now.utcOffset(), 'm'));
  const utcDate = getUtcDateFromString(date);

  return utcDate.isAfter(utcToday, 'd');
}

export function DiffHours(dt1: moment.Moment, dt2: moment.Moment): number {
  const dt1Cloned = dt1.clone();
  const dt2Cloned = dt2.clone();

  return moment.duration(dt2Cloned.diff(dt1Cloned)).asHours();
}

export function sortByDate(d1: string, d2: string) {
  return moment.utc(d1).diff(moment.utc(d2))
};