import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild, Router, CanActivate } from '@angular/router';
import { Observable, of } from 'rxjs';
import { UserService } from '../services/user.service';
import { getErrorMessage } from '../shared/utils/Utils';
import { User } from '../models/user/User';
import { ServiceError } from '../models/ServiceError';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AccessGuard implements CanActivate, CanActivateChild {

  constructor(
    private router: Router,
    private userService: UserService
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, _: RouterStateSnapshot): Observable<boolean> {
    const minUserLevel = route.data.minUserLevel;
    if (!minUserLevel) {
      return of(true);
    }
    return this.userService
      .getUser()
      .pipe(
        catchError(e => of(new ServiceError(true, getErrorMessage(e)))),
        map(data => {
          const error = data as ServiceError;
          const user = data as User;
          if (!user || error.isError) {
            return false;
          }
          const result = user.level.id >= minUserLevel;
          if (!result) {
            this.router.navigateByUrl('/');
          }
          return result;
        })
      );
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.canActivate(route, state);
  }

}