<nav class="navbar navbar-fixed-top" role="navigation" style="margin-bottom: 0;">

  <ul class="nav navbar-top-links navbar-left pull-left">
    <li class="nav-item collapsable-burger">
      <a class="navbar-minimalize" (click)="toggleCollapsedSideabar()">
        <em class="fa fa-bars"></em>
      </a>
    </li>
    <li class="nav-item">
      <a [routerLink]="['/']" class="logo-header">
        <div class="logo-container">
          <img class="logo-container" src="assets/img/App-Logo.png" alt="ORBiT Logo" draggable="false" />
        </div>
      </a>
    </li>
  </ul>

  <ul class="nav navbar-top-links navbar-right pull-right" style="margin-right:0px;">
    <li class="dropdown">

      <a class="dropdown-toggle account-info" data-toggle="dropdown">
        <em class="fa fa-user fa-2x" style="padding-bottom: 0;"></em>
        <span>{{user?.name}}</span>
      </a>
      <ul class="dropdown-menu dropdown-alerts account-info-popup border-around"
          (click)="$event.stopPropagation(); $event.preventDefault();">
        <li>
          <h3>
            {{user?.name}} - User Details
            <i class="fa fa-info-circle float-right"></i>
          </h3>
        </li>
        <li class="divider"></li>
        <li>
          <div>
            Logged in as:
            <strong>{{user?.email}}</strong>
          </div>
        </li>
        <li class="divider"></li>
        <li>
          <div>
            User Level:
            <strong>{{ getUserLevel() }}</strong>
          </div>
        </li>
        <li class="divider"></li>
        <li>
          <div>
            Assets:
            <strong>{{ getUserAssets() }}</strong>
          </div>
        </li>
        <li class="divider"></li>
        <li>
          <button class="btn btn-primary" (click)="resetPassword()">
            <i class="fas fa-key" aria-hidden="true"></i> Change password
          </button>
          <button class="btn btn-info float-right" (click)="logout()">
            <i class="fas fa-sign-out-alt" aria-hidden="true"></i> Log out
          </button>
        </li>
        <li><!--Need empty li to sort styling--></li>
      </ul>
    </li>
    <li class="dropdown">
      <a style="margin-left:6px" (click)="showHelp()">
        <i class="far fa-question-circle fa-2x"></i>
      </a>
    </li>
    <li class="dropdown">
      <a #fsbutton style="margin-left:6px" (click)="toggleFullscreen()">
        <em class="fa fa-2x" [ngClass]="fullscreenClass"></em>
      </a>
    </li>
  </ul>
</nav>