import { Component, OnInit, Input } from '@angular/core';
import { EditModeDetails } from '../../models/EditModeDetails';

@Component({
  selector: 'shared-edit-mode-lock',
  templateUrl: './edit-mode-lock.component.html',
  styleUrls: ['./edit-mode-lock.component.scss']
})
export class EditModeLockComponent implements OnInit {

  @Input() isInEditMode: boolean;
  @Input() isInEditModeElsewhere: boolean = true;
  @Input() isInEditModeElsewhereByCurrentUser: boolean;
  @Input() editModeDetails: EditModeDetails;

  constructor() { }

  ngOnInit() { }
}
