<div class="tabs-container">
  <ul class="nav nav-tabs" *ngIf="tabs && tabs.length">
    <li *ngFor="let tab of tabs" [class.active]="isTabActive(tab)">
      <a [routerLink]="[tab.path]">
        <i *ngIf="tab.icon" class="fa" [ngClass]="tab.icon"></i>
        <span>{{tab.name}}</span>
      </a>
    </li>
  </ul>
  <div class="tab-content">
    <div class="tab-pane active">
      <div [ngClass]="{'panel-body': tabs && tabs.length}">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>