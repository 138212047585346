import { Component, OnInit, AfterViewInit, ViewChild, TemplateRef, ViewContainerRef } from '@angular/core';

import { NavigationService } from './services/navigation.service';
import { UserService } from '../../services/user.service';
import { DialogService } from '../../shared/services/dialog.service';

import { SelfUnsubscriberBase } from '../../shared/components/base/SelfUnsubscriber';

import { User } from '../../models/user/User';

import { getErrorMessage } from '../../shared/utils/Utils';
import { ModalService } from './modal.service';

declare var BootstrapInspinia: () => void;
declare var $: any;

@Component({
  selector: 'orbit-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent extends SelfUnsubscriberBase implements OnInit, AfterViewInit {

  user: User;

  constructor(
    private userService: UserService,
    private navService: NavigationService,
    private dialogService: DialogService,
    private modalService: ModalService
  ) {
    super();

    this.subscriptions.push(this.modalService.events.subscribe(event => {
      if (!this.modalContainer) {
        return;
      }
      if (event.type === 'create') {
        let el = event.template.createEmbeddedView(null);

        this.modalContainer.insert(el);
        this.modalRef = el.rootNodes.find(el => el.classList && el.classList.contains('modal'))
        $(this.modalRef).modal('toggle');

      } else if (event.type === 'destroy') {
        $(this.modalRef).modal('hide');
        this.modalContainer.clear();
      }
      else {
        throw new Error(`Unknown event type - ${event.type}`);
      }
    }));
  }

  private modalRef: any;

  @ViewChild('modalContainer', { read: ViewContainerRef }) modalContainer: ViewContainerRef;
  templateRef: TemplateRef<any>;

  ngOnInit() {
    this.subscriptions.push(this.userService.getUser()
      .subscribe(usr => {
        this.user = usr;
        this.navService.initRouter(this.user);
      },
        err => this.dialogService.showError('An unexpected error has occurred!', getErrorMessage(err)
        ))
    );
  }

  ngAfterViewInit() {
    BootstrapInspinia();
  }
}