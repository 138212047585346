<orbit-loader *ngIf="isLoading"></orbit-loader>

<div class="login-box" *ngIf="!isLoading">
  <div class="logo-container">
    <img class="img-fluid" src="assets/img/App-Logo.png" alt="ORBiT Logo" draggable="false" />
  </div>

  <h2>Log In</h2>

  <form (ngSubmit)="onSubmit()">
    <div class="form-group">
      <input-wrapper labelText="" labelClass="hidden" contentClass="col-lg-12 no-padding" [model]="data.email"
                     [validate]="modelValidator" validationProperty="email" [forceValidation]="isValidating" [control]="email">
        <input type="email" placeholder="Email" class="form-control" name="data.email" [(ngModel)]="data.email"
               #email="ngModel" [disabled]="isLoading" />
      </input-wrapper>
    </div>

    <div class="form-group">
      <input-wrapper class="" labelText="" labelClass="hidden" contentClass="col-lg-12 no-padding"
                     [model]="data.password" [validate]="modelValidator" validationProperty="password"
                     [forceValidation]="isValidating" [control]="password">
        <input type="password" placeholder="Password" class="form-control m-t-sm" name="data.password"
               [(ngModel)]="data.password" #password="ngModel" [disabled]="isLoading" />
      </input-wrapper>
    </div>
    <div class="form-group m-b-none">
      <button [disabled]="isLoading" class="btn btn-login btn-secondary m-t-sm"><i class="fas fa-sign-in-alt" aria-hidden="true"></i> Login</button>
    </div>
  </form>
</div>
