import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';

import { UserService } from '../../../services/user.service';
import { LayoutService } from '../services/layout.service';
import { DialogService } from '../../../shared/services/dialog.service';

import { ResetPasswordComponent } from '../../account/reset-password/reset-password.component';

import { User } from '../../../models/user/User';
import { UserLevels } from '../../../models/user/UserLevel';

@Component({
  selector: 'orbit-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input() user: User;

  @ViewChild('fsbutton', { static: true }) fsbutton;  // the fullscreen button
  fullscreenClass: string = 'fa-expand';

  constructor(
    private userService: UserService,
    private layoutService: LayoutService,
    private dialogService: DialogService,
    private modalService: BsModalService
  ) {
    window.onresize = () => {
      this.fullscreenClass = this._isInFullscreen() ? 'fa-compress' : 'fa-expand';
    };
  }

  ngOnInit() { }

  getUserAssets(): string {
    if (!this.user || !this.user.level) return '';
    if (this.user.level.id === UserLevels.Administrator) {
      return 'All';
    } else if (this.user.assets.length > 0) {
      return this.user.assets.map(ua => ua.asset.name).join(', ');
    } else {
      return 'None';
    }
  }

  getUserLevel() {
    return this.user ? this.user.level.name : '';
  }

  showHelp() {
    window.open('api/Help', '_blank');
  }

  resetPassword() {
    this.modalService.show(ResetPasswordComponent, {
      backdrop: 'static',
      class: 'position-login-component'
    });
  }

  logout() {
    this.dialogService.showDialog({
      title: `Log out?`,
      text: `Are you sure you want to log out?`,
      type: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        this.dialogService.showLoading();
        this.userService.logout();
      }
    });
  }

  toggleCollapsedSideabar() {
    this.layoutService.changeSidebarState();
  }

  toggleFullscreen() {
    // Works as expected in IE11, Edge, Firefox and Chromium-based browsers
    // Does not integrate well with the use of F11 for fullscreen mode.
    // However if the users know how to enter fullscreen through F11, they will know how to exit too.
    let doc: any = document;
    let docElem: any = document.documentElement;
    let bodyElem: any = document.body;

    if ((!doc.fullScreenElement &&
      !doc.mozFullScreen &&
      !doc.webkitIsFullScreen &&
      !doc.msFullscreenElement)
      && !this._isInFullscreen()) {
      if (bodyElem.requestFullScreen) {
        bodyElem.requestFullScreen();
      } else if (docElem.mozRequestFullScreen) {
        docElem.mozRequestFullScreen();
      } else if (docElem.webkitRequestFullScreen) {
        docElem.webkitRequestFullScreen();
      } else if (bodyElem.msRequestFullscreen) {
        bodyElem.msRequestFullscreen();
      }
    } else {
      if (doc.cancelFullScreen) {
        doc.cancelFullScreen();
      } else if (doc.mozCancelFullScreen) {
        doc.mozCancelFullScreen();
      } else if (doc.webkitCancelFullScreen) {
        doc.webkitCancelFullScreen();
      } else if (doc.msExitFullscreen) {
        doc.msExitFullscreen();
      }
    }
  }

  private _isInFullscreen() {
    const maxHeight = window.screen.height;
    const maxWidth = window.screen.width;
    const curHeight = document.body.clientHeight;
    const curWidth = document.body.clientWidth;

    return maxWidth == curWidth && maxHeight == curHeight;
  }
}