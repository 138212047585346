<div class="not-found-container unauthorised-panel centered animated fadeIn no-borders">
  <div class="fade-in logo-container">
    <img alt="ORBiT" draggable="false" style="width: 300px;" src="assets/img/App-Logo.png" />
  </div>
  <div class="white-bg unauthorised-panel relative-container extra-padding">
    <div class="unauthorised-icon">
      <i class="fa fa-exclamation-triangle"></i>
    </div>
    <div class="unauthorised">
      <h1>Page Not Found #404</h1>
      <hr class="divider" />
      <h2 class="m-t-xl">The page you are looking for could not be found.</h2>
      <h2>Please contact an administrator if you believe this to be an error.</h2>
      <div class="error-page-go-home">
        <a [routerLink]="'/home'">Go Back to ORBiT</a>
      </div>
    </div>
  </div>
</div>

