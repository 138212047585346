import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'tooltip-wrapper',
  templateUrl: './tooltip-wrapper.component.html',
  styleUrls: ['./tooltip-wrapper.component.scss']
})
export class TooltipWrapperComponent implements OnInit {

  @Input() tooltip: string;
  @Input() tooltipStyle: any;
  @Input() position: string = 'bottom';

  constructor() { }

  ngOnInit() { }

}
