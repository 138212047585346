import { Injectable } from '@angular/core';
import { ApiService } from '../shared/services/api.service';
import { AppSetting } from '../models/settings/AppSettings';
import { Observable } from 'rxjs';
import { ApiErrorOptions } from '../shared/models/ApiErrorOptions';

@Injectable({
  providedIn: 'root'
})
export class AppSettingsService {

  constructor(private apiService: ApiService) { }

  private _apiBaseUrl: string = 'api/AppSettings';

  getSettings(): Observable<AppSetting[]> {
    return this.apiService.makeGet(this._apiBaseUrl,
      new ApiErrorOptions({
        errorPrefix: 'Unable to load application settings. Error details:',
        showConfirmButton: false
      }));
  }

  editSetting(setting: AppSetting): Observable<AppSetting> {
    return this.apiService.makePut(this._apiBaseUrl, setting,
      new ApiErrorOptions({
        errorPrefix: 'An error occurred while saving changes. Error details:'
      }));
  }
}