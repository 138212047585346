import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { IFilterAngularComp } from 'ag-grid-angular';
import { IFilterParams, RowNode, IDoesFilterPassParams } from 'ag-grid-community';
import * as moment from 'moment';

import flatpickr from 'flatpickr';
import { BaseOptions } from 'flatpickr/dist/types/options';
import { Instance } from 'flatpickr/dist/types/instance';

import { DatePickerSelection } from '../../../../models/DatePickerSelection';
import { getUtcDate } from '../../../../utils/MomentUtils';

@Component({
  selector: 'shared-date-range-filter',
  templateUrl: './date-range-filter.component.html',
  styleUrls: ['./date-range-filter.component.scss']
})
export class DateRangeFilterComponent implements OnInit, IFilterAngularComp {

  @ViewChild('picker', { static: true }) pickerElement: ElementRef;

  private params: IFilterParams;
  private valueGetter: (rowNode: RowNode) => any;

  public selectedRange: DatePickerSelection;

  pickerConfig = <BaseOptions>{
    mode: 'range',
    dateFormat: "d/m/Y",
    inline: true,
    onChange: this.onDateSelected.bind(this)
  };

  datePicker: Instance;

  ngOnInit(): void {
    this.datePicker = flatpickr(this.pickerElement.nativeElement, this.pickerConfig);
  }

  agInit(params: IFilterParams): void {
    this.params = params;
    this.valueGetter = params.valueGetter;
  }

  isFilterActive(): boolean {
    return !!this.selectedRange &&
      !!this.selectedRange.start &&
      !!this.selectedRange.end;
  }

  doesFilterPass(params: IDoesFilterPassParams): boolean {
    if (!this.selectedRange) {
      return true;
    }
    const currentDate = moment.utc(this.valueGetter(params.node)).startOf('day');
    const beginDate = this.selectedRange.start.startOf('day');
    const endDate = this.selectedRange.end.startOf('day');
    return (currentDate.isAfter(beginDate) || currentDate.isSame(beginDate)) &&
      (currentDate.isBefore(endDate) || currentDate.isSame(endDate));
  }

  getModel(): any {
    return { selectedDate: this.selectedRange };
  }

  setModel(model: any): void {
    if (!!model) {
      this.selectedRange = model.selectedDate;
    }
    else {
      this.datePicker.clear();
      this.selectedRange = undefined;
    }
  }

  onDateSelected(selectedDates, _, __) {
    this.selectedRange = new DatePickerSelection(getUtcDate(selectedDates[0]), getUtcDate(selectedDates[1]));

    this.params.filterChangedCallback();
  }

  onClear() {
    this.datePicker.clear();
    this.selectedRange = undefined;
    this.params.filterChangedCallback();
  }
}