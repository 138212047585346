import { Constants } from '../utils/Constants';

export class ApiErrorOptions {
  title: string = Constants.Modals.ERROR_TITLE;
  errorPrefix: string;
  errorSuffix: string;
  showConfirmButton: boolean = true;

  public constructor(init?: Partial<ApiErrorOptions>) {
    Object.assign(this, init);
  }
}
