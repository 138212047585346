export function compare(obj1, obj2) {
  return JSON.stringify(obj1) == JSON.stringify(obj2);
}

export function compareEntitiesById(obj1: any, obj2: any) {
  return obj1 && obj2 ? obj1.id === obj2.id : obj1 === obj2;
}

export function compareEntitiesByValue(obj1: any, obj2: any) {
  return obj1 && obj2 ? obj1.value === obj2.value : obj1 === obj2;
}

export function compareEntities(obj1: any, obj2: any, prop?: string) {
  if (!prop) {
    return obj1 === obj2;
  }
  return obj1 && obj2 ? obj1[prop] === obj2[prop] : obj1 === obj2;
}

export function compareStrings(s1: string, s2: string): number {
  return s1 < s2 ? -1 : (s1 > s2 ? 1 : 0);
}

export function compareStringsIgnoreCase(s1: string, s2: string): number {
  const v1 = s1.toLowerCase();
  const v2 = s2.toLowerCase();
  return v1 < v2 ? -1 : (v1 > v2 ? 1 : 0);
}

export function compareStringDates(s1: string, s2: string): number {
  s1 = s1.split('/').reverse().join('');
  s2 = s2.split('/').reverse().join('');
  return s1 > s2 ? 1 : s1 < s2 ? -1 : 0;
}

export function sortByProperty(property) {
  let sortOrder = 1;
  //- Before property will sort in reverse order
  if (property[0] === "-") {
    sortOrder = -1;
    property = property.substr(1);
  }
  return (a, b) => ((a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0) * sortOrder;
}