<div>
  <button class="btn btn-primary" (click)="onEditClick()" *ngIf="!isInEditMode" [disabled]="!canEdit || disabled">
    <i class="fas fa-pencil-alt" aria-hidden="true"></i> Edit
  </button>
</div>
<button class="btn btn-danger pull-right" (click)="onEditCancelClick()" *ngIf="isInEditMode" [disabled]="disabled">
  <i class="fa fa-times" aria-hidden="true"></i> Cancel Edit
</button>
<div class="tooltip-container pull-right" *ngIf="isInEditMode">
  <div class="tooltip-element">
    <div class="pull-right" style="margin-top: 8px; margin-right: 10px;">{{ editModeDetails.expiresIn | shortTimespan }}</div>
    <i class="far fa-clock" style="font-size: 36px; margin-right: 5px;"></i>
  </div>
  <div class="tooltip fade bottom in tooltip-margin" [ngClass]="{'hidden-tooltip' : (editModeDetails.expiresIn | timespan:'>00:05:00'), 'visible-tooltip' : (editModeDetails.expiresIn | timespan:'<=00:05:00'), 'visible-now-tooltip' : (editModeDetails.expiresIn  | timespan) == 'now'}">
    <div class="tooltip-arrow"></div>
    <div class="tooltip-inner">Your lock expires {{editModeDetails.expiresIn | timespan}}{{(editModeDetails.expiresIn  | timespan) == 'now' ? '' : ' of inactivity'}}.</div>
  </div>
</div>
