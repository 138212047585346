import { UserLevel } from './UserLevel';
import { UserAsset } from './UserAsset';

export class User {
  id: number;
  isActive: boolean;
  isSetup: boolean;

  name: string;
  email: string;
  token: any;

  accountActivationToken: string;

  assets: UserAsset[];
  level: UserLevel;
}