import { Injectable } from '@angular/core';
import { Router, NavigationEnd, Event } from '@angular/router';
import { Subject, ReplaySubject, BehaviorSubject, Subscriber, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { clone } from '../../../shared/utils/Utils';
import { SelfUnsubscriberBase } from '../../../shared/components/base/SelfUnsubscriber';
import { NavMenuItem } from '../utils/NavMenuItem';
import { appMenuItems, caissonsMenuItems, structuralTopsidesMenuItems, } from '../utils/MenuConfig';
import { User } from '../../../models/user/User';

declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class NavigationService extends SelfUnsubscriberBase {

  constructor(
    private router: Router
  ) {
    super();

    this.subscriptions.push(
      this.router.events.subscribe(event => this.routerEvents.next(event))
    );
  }

  private user: User;
  private subject: Subject<any> = new ReplaySubject(1);
  private routerEvents = new BehaviorSubject<Event>(undefined);

  public subscribe(subscriber: Subscriber<any>): Subscription {
    return this.subject.subscribe(subscriber);
  }

  public initRouter(user: User) {
    this.user = user;

    this.subscriptions.push(this.routerEvents
      .pipe(
        filter(e => !!e && e instanceof NavigationEnd),
        map((e: NavigationEnd) => e.urlAfterRedirects)
      )
      .subscribe(this._onRouteChange.bind(this))
    );
  }

  private _onRouteChange(url: string): void {
    this._computeCurrentModuleState(url);
    this._dismissModalStack();
  }

  private _computeCurrentModuleState(url: string): void {
    let moduleMenuItems: NavMenuItem[] = [];
    let moduleName = '';
    let moduleTitle = '';
    const currentModulePrefixPath = url.substr(1, url.substr(1).indexOf('/'));
    switch (currentModulePrefixPath) {
      case 'structural-topsides':
        moduleName = 'StructuralTopsidesModule';
        moduleTitle = 'Structural Topsides';
        moduleMenuItems = structuralTopsidesMenuItems;
        break;
      case 'caissons':
        moduleName = 'CaissonsModule';
        moduleTitle = 'Caissons';
        moduleMenuItems = caissonsMenuItems;
        break;
      default:
        moduleName = 'AppModule';
        moduleTitle = '';
        moduleMenuItems = appMenuItems;
        break;
    }
    moduleMenuItems = clone(moduleMenuItems);

    const userAccessLevel = !!this.user ? this.user.level.id : 0;

    const filterMenuPermissions = (menuItems: NavMenuItem[]) => {
      if (!menuItems) return menuItems;
      menuItems.forEach(mi => mi.children = filterMenuPermissions(mi.children));
      return menuItems.filter(mi => mi.route || (mi.children && mi.children.length))
        .filter(mi => !mi.route || !mi.route.data || !mi.route.data.minUserLevel || userAccessLevel >= mi.route.data.minUserLevel);
    };

    moduleMenuItems = filterMenuPermissions(moduleMenuItems);
    this.subject.next({ moduleName, moduleTitle, moduleMenuItems });
  }

  private _dismissModalStack(): void {
    $('.modal').modal('hide');
  }
}