import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { IDoesFilterPassParams, RowNode, IFilterParams } from 'ag-grid-community';
import { IFilterAngularComp } from 'ag-grid-angular';
import { MultiselectDropdownComponent, IMultiSelectSettings } from 'angular-2-dropdown-multiselect';

interface ISetFilterParams extends IFilterParams {
  displayField: string;
  blankValueLabel: string;
}

@Component({
  selector: 'shared-set-filter',
  templateUrl: './set-filter.component.html',
  styleUrls: ['./set-filter.component.scss']
})
export class SetFilterComponent implements OnInit, IFilterAngularComp {

  constructor(private changeDetector: ChangeDetectorRef) { }

  private params: ISetFilterParams;

  public dataSet: any[];
  public selectedItems: any[];
  private valueGetter: (rowNode: RowNode) => any;
  public text: string = '';

  @ViewChild('input', { static: true }) select: MultiselectDropdownComponent;

  multiSelectSettings: IMultiSelectSettings = {
    enableSearch: true,
    checkedStyle: 'fontawesome',
    buttonClasses: 'btn btn-default btn-block',
    dynamicTitleMaxItems: 5,
    displayAllSelectedText: true,
    showCheckAll: true,
    showUncheckAll: true,
    closeOnClickOutside: false,
    closeOnSelect: false,
    stopScrollPropagation: true
  };

  ngOnInit() {
    this.select.isVisible = true;
  }

  agInit(params: ISetFilterParams): void {
    this.params = params;
    this.valueGetter = params.valueGetter;
  }

  afterGuiAttached?(_?: { hidePopup?: Function }): void {
    this._initData();
  }

  private _initData() {
    this.dataSet = [];
    this.params.rowModel.forEachNode(r => {
      // Discard any rows already filtered out through other filters
      if (!this.params.doesRowPassOtherFilter(r)) return;

      const val = this._displayValueGetter(this.valueGetter(r));
      const result = {
        id: val,
        name: val
      };
      if (!!this.dataSet.find(e => e.id === val)) {
        return;
      }
      this.dataSet.push(result);
    });
    this.dataSet.sort(this._compareData);
    this.changeDetector.detectChanges();
  }

  private _compareData(a: any, b: any): number {
    if (!!a.name && !!b.name) return a.name > b.name ? 1 : (a.name < b.name ? - 1 : 0);
    // Scenario for either or both values being empty
    return !a.name ? 1 : (!b.name ? -1 : 0);
  }

  private _displayValueGetter(value) {
    if (!value) {
      return !!this.params.blankValueLabel ? this.params.blankValueLabel : value;
    }
    if (!this.params || !this.params.displayField) {
      return value;
    }
    return value[this.params.displayField];
  }

  isFilterActive(): boolean {
    return this.selectedItems && !!this.selectedItems.length;
  }

  doesFilterPass(params: IDoesFilterPassParams): boolean {
    const value = this.valueGetter(params.node);
    return !!this.selectedItems ? this.selectedItems.some(item => item === this._displayValueGetter(value)) : true;
  }

  getModel() {
    return { selectedItems: this.selectedItems };
  }

  setModel(model: any): void {
    this.selectedItems = !!model ? model.selectedItems : undefined;
  }

  onSelectedItemsChange(_) {
    this.params.filterChangedCallback();
    this.changeDetector.detectChanges();
  }
}