import { ColDef, ColGroupDef, GridOptions } from 'ag-grid-community';
import { getGridOptions } from '../ag-grid';
import * as moment from 'moment';

import { LoadableComponentBase } from './LoadableComponent';

import { DEFAULT_DATE_FORMAT } from '../../utils/Formatters';
import { compareStringsIgnoreCase } from '../../utils/Comparers';
import { clone } from '../../utils/Utils';

export abstract class GridComponentBase<T> extends LoadableComponentBase {
  // default grid options
  public columnTypes: any = {};
  public columnDefs: (ColDef | ColGroupDef)[] = [];
  public gridOptions: GridOptions = getGridOptions(<GridOptions>{
    columnDefs: this.columnDefs,
    columnTypes: this.columnTypes,
  });

  private _data: T[];
  set data(value: T[]) {
    if (this.currentSelection)
      this.resetSelection();

    this._data = value;
  }

  get data(): T[] {
    return this._data;
  }

  currentSelection: T;
  updatedItem: T;
  currentSelectionIdx: number = -1;

  /**Called when a row is selected inside the grid. */
  abstract onRowSelected($event: any): void;
  /**Called when a row is doubleClicked. */
  abstract onRowDoubleClicked(_: any): void;
  /**This is used by the input-wrapper to display error messages. Use this in combination with ValidationService. */
  abstract validateModel(prop: string): string;
  /**This should be used when saveChanges() is called. If the returned value is FALSE then save operation should be aborted. */
  abstract isModelValid(): boolean;

  protected resetSelection(): void { }

  /**Used for convenience as it will be binded in template. */
  get modelValidator() {
    return this.validateModel.bind(this);
  }

  protected addColumnDef(colDefs: (ColDef | ColGroupDef)) {
    this.columnDefs.push(colDefs);
  }

  protected addColumnDefs(colDefs: (ColDef | ColGroupDef)[]) {
    this.columnDefs.push(...colDefs);
  }

  protected resetColumnDefs() {
    this.columnDefs.splice(0, this.columnDefs.length);
  }

  protected sortAndRefreshDateColumnDefs() {
    const orderedColDefs = this.columnDefs
      .filter(cd => !(cd as ColDef).pinned && moment(cd.headerName, DEFAULT_DATE_FORMAT).isValid())
      .sort((c1, c2) => {
        return moment.utc(c1.headerName, DEFAULT_DATE_FORMAT).diff(moment.utc(c2.headerName, DEFAULT_DATE_FORMAT));
      });

    const newColDefs = this.columnDefs.filter(cd => (cd as ColDef).pinned);
    newColDefs.push(...orderedColDefs);

    this.resetColumnDefs();

    this.columnDefs.push(...newColDefs);

    if (this.gridOptions.api) {
      this.gridOptions.api.setColumnDefs([]);
      this.refreshColumnDefs();
    }
  }

  protected sortAndRefreshColumnDefs() {
    if (this.gridOptions.api) {
      const orderedColDefs = this.columnDefs
        .filter(cd => !(cd as ColDef).pinned)
        .sort((c1, c2) => {
          return compareStringsIgnoreCase(c1.headerName, c2.headerName);
        });

      const newColDefs = this.columnDefs.filter(cd => (cd as ColDef).pinned);
      newColDefs.push(...orderedColDefs);

      this.resetColumnDefs();
      this.gridOptions.api.setColumnDefs([]);
      this.columnDefs.push(...newColDefs);
    }

    this.refreshColumnDefs();
  }

  protected refreshColumnDefs() {
    if (this.gridOptions.api)
      this.gridOptions.api.setColumnDefs(this.columnDefs);
  }

  protected addColumnType(key: string, colType: any) {
    this.columnTypes[key] = colType;
  }

  protected setCurrentSelection() {
    const currentIdx = this.data.indexOf(this.currentSelection);
    if (currentIdx < 0) {
      this.currentSelection = clone(this.data[this.currentSelectionIdx]);
    }
  }

  protected selectRowWithCurrentSelection(rowData: T) {
    if (!this.currentSelection) return;

    this.gridOptions.api.forEachNode(node => {
      if (node.data == rowData || (node.data.id && (rowData as any).id && node.data.id == (rowData as any).id)) {
        node.setSelected(true);
      }
    });
  }

  protected updateGridAfterAdd(data: T) {
    this.gridOptions.api.updateRowData({ add: [data] });
  }

  protected updateGridAfterEdit(data: T) {
    let rowNode = this.gridOptions.api.getSelectedNodes()[0];
    if (!!rowNode) rowNode.setData(data);
    this.gridOptions.api.updateRowData({ update: [data] });
  }

  protected updateGridAfterDelete() {
    this.gridOptions.api.updateRowData({ remove: this.gridOptions.api.getSelectedRows() });
  }
}