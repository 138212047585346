import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'shared-button-cell-renderer',
  templateUrl: './button-cell-renderer.component.html',
  styleUrls: ['./button-cell-renderer.component.scss']
})
export class ButtonCellRendererComponent implements ICellRendererAngularComp {
  
  params: any;

  constructor() { }

  agInit(params: any): void {
    this.params = params;
  }

  refresh(_: any): boolean {
    return false;
  }

  onClick() {
    this.params.onClick(this.params.node);
  }
}
