import { Injectable } from '@angular/core';
import Swal, { SweetAlertType } from 'sweetalert2';
import { SharedModule } from '../shared.module';
import { getErrorMessage, getValidationErrors } from '../utils/Utils';
import { Constants } from '../utils/Constants';

@Injectable({
  providedIn: SharedModule
})
export class DialogService {
  showDialog(titleOrOptions: string | {}, message?: string, type?: SweetAlertType): Promise<any> {
    if (typeof titleOrOptions === 'object') {
      return Swal.fire(Object.assign({
        allowOutsideClick: false,
        heightAuto: false
      }, titleOrOptions));
    } else if (typeof titleOrOptions === 'string') {
      return Swal.fire({
        title: titleOrOptions,
        text: message,
        type: type,
        allowOutsideClick: false,
        heightAuto: false
      });
    } else {
      throw new Error('Unknown parameter type');
    }
  }

  showLoadingDialog(text: string): Promise<any> {
    return this.showDialog({
      title: Constants.Modals.LOADING_TITLE,
      text: text,
      onOpen: () => {
        this.showLoading();
      }
    });
  }

  showError(title: string, message: string, extraOptions?: {}) {
    return this.showDialog(Object.assign({
      title: title,
      html: message,
      type: 'error',
      showCancelButton: false,
      showConfirmButton: false
    }, extraOptions));
  }

  showErrorList(e, message: string, extraOptions?: {}, ) {
    return this.showDialog(Object.assign({
      title: 'Error!',
      html: `${message ? message : 'An error has occurred.'} Error details: ${getErrorMessage(e, getValidationErrors)}`,
      type: 'error',
      showCancelButton: false,
      showConfirmButton: true
    }, extraOptions));
  }

  showLoading() {
    Swal.showLoading();
    const cancelButton = Swal.getCancelButton();
    if (cancelButton) {
      cancelButton.style.display = 'none';
    }
  }

  showLoader(title: string, text?: string): Promise<any> {
    return Swal.fire({
      title: title,
      text: text,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      onOpen: () => {
        Swal.showLoading()
      }
    });
  }

  closeDialog() {
    Swal.close();
  }
}

