import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscriber } from 'rxjs';
import { NavigationService } from '../services/navigation.service';
import { LayoutService } from '../services/layout.service';

import { SelfUnsubscriberBase } from '../../../shared/components/base/SelfUnsubscriber';

import { NavMenuItem } from '../utils/NavMenuItem';
import { getCopyrightYears } from '../../../shared/utils/Utils';

@Component({
  selector: 'orbit-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent extends SelfUnsubscriberBase implements OnInit {

  private LAYOUT_INDEX: number = 4;

  copyrightYears: string;
  currentModulePrefix = '';
  menuItems: NavMenuItem[] = [];
  homeMenuItem: NavMenuItem = {
    name: 'Home',
    icon: 'fa-home',
    route: { path: '' }
  };
  private _toggledMenuItem: NavMenuItem;
  isSidebarCollapsed: boolean;

  constructor(
    private router: Router,
    private moduleNavigationChangeService: NavigationService,
    private layoutService: LayoutService
  ) {
    super();
    this.subscriptions.push(
      this.moduleNavigationChangeService.subscribe(new Subscriber(this._onModuleChanged.bind(this)))
    );

    this.subscriptions.push(
      this.layoutService.sidebarStateChanged.subscribe(isCollapsed => {
        this.isSidebarCollapsed = isCollapsed;
      })
    )
  }

  ngOnInit() {
    this.copyrightYears = getCopyrightYears();

    this.router.events.subscribe((_) => {
      // Always scroll view to the top following navigation
      window.scrollTo(0, 0);
    });
  }

  private prefixMenuItem(mi: NavMenuItem, prefix: string) {
    if (mi.route) {
      mi.route.path = `${prefix ? '/' : ''}${prefix}/${mi.route.path}`;
    }
    if (!mi.children) {
      return;
    }
    mi.children.forEach(c => this.prefixMenuItem(c, prefix));
  }

  private getModulePrefix(moduleInfo) {
    const routeConfig = this.router.config[this.LAYOUT_INDEX].children
      .find(c => !!c.data && !!c.data.moduleName && c.data.moduleName === moduleInfo.moduleName);
    const prefix = !!routeConfig ? routeConfig.path : '';
    return prefix;
  }

  private _onModuleChanged(moduleInfo) {
    this.menuItems = moduleInfo.moduleMenuItems;
    if (!this.router.config || !this.router.config.length) {
      return;
    }
    this.currentModulePrefix = this.getModulePrefix(moduleInfo);
    this.menuItems.forEach(mi => this.prefixMenuItem(mi, this.currentModulePrefix));
    this._determineToggleState();
  }

  private _determineToggleState() {
    if (this._toggledMenuItem) {
      return;
    }
    for (let i = 0; i < this.menuItems.length; i++) {
      if (this.isMenuItemActive(this.menuItems[i])) {
        this.toggleMenuItem(this.menuItems[i]);
        break;
      }
    }
  }

  isMenuItemActive(menuItem: NavMenuItem): boolean {
    if (!menuItem) {
      return false;
    }
    if (menuItem.route) {
      if (!menuItem.route.path.replace(`/${this.currentModulePrefix}`, '')) {
        return this.router.isActive(menuItem.route.path, true);
      }
      const currentUrl = this.router.routerState.snapshot.url;
      return currentUrl == menuItem.route.path || currentUrl.startsWith(menuItem.route.path);
    }
    return !!(menuItem.children || []).find(c => this.isMenuItemActive(c));
  }

  isMenuItemToggled(menuItem: NavMenuItem): boolean {
    if (!this._toggledMenuItem) {
      return false;
    }
    return this._toggledMenuItem.name === menuItem.name;
  }

  toggleMenuItem(menuItem) {
    if (this.isMenuItemToggled(menuItem)) {
      this._toggledMenuItem = undefined;
    } else {
      this._toggledMenuItem = menuItem;
    }
  }

}