import { NgModule } from '@angular/core';
import { Routes, RouterModule, Route, PreloadAllModules } from '@angular/router';

import { HomeComponent } from './components/home/home.component';
import { LayoutComponent } from './components/layout/layout.component';
import { LoginComponent } from './components/account/login/login.component';
import { NotFoundComponent } from './components/layout/not-found/not-found.component';
import { PasswordSetupComponent } from './components/account/password-setup/password-setup.component';
import { UserManagementComponent } from './components/settings/user-management/user-management.component';
import { AppSettingsComponent } from './components/settings/app-settings/app-settings.component';

import { AuthGuard } from './guards/auth.guard';
import { AccessGuard } from './guards/access.guard';

import { UserLevels } from './models/user/UserLevel';

export const appRoutes: { [key: string]: Route } = {
  defaultRoute: { path: '', redirectTo: 'home', pathMatch: 'full' },
  homeRoute: { path: 'home', component: HomeComponent },
  userManagementRoute: { path: 'user-management', component: UserManagementComponent, canActivate: [AuthGuard, AccessGuard], data: { minUserLevel: UserLevels.Administrator } },
  appSettingsRoute: { path: 'app-settings', component: AppSettingsComponent, canActivate: [AuthGuard, AccessGuard], data: { minUserLevel: UserLevels.Administrator } },

  structuralTopsidesRoute: {
    path: 'structural-topsides',
    loadChildren: () => import('./modules/structural-topsides/structural-topsides.module').then(m => m.StructuralTopsidesModule),
    canActivate: [AuthGuard, AccessGuard],
    data: { minUserLevel: UserLevels.ReadOnly, moduleName: 'StructuralTopsidesModule' }
  },

  caissonsRoute: {
    path: 'caissons',
    loadChildren: () => import('./modules/caissons/caissons.module').then(m => m.CaissonsModule),
    canActivate: [AuthGuard, AccessGuard],
    data: { minUserLevel: UserLevels.ReadOnly, moduleName: 'CaissonsModule' }
  }
};

const routes: Routes = [
  {
    path: 'printing',
    loadChildren: () => import('./modules/printing/printing.module').then(m => m.PrintingModule),
    canActivate: [AuthGuard, AccessGuard],
    data: { minUserLevel: UserLevels.ReadOnly }
  },

  { path: 'not-found', component: NotFoundComponent },
  { path: 'login', component: LoginComponent },
  { path: 'setup/:token', component: PasswordSetupComponent },

  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      appRoutes.defaultRoute,
      appRoutes.homeRoute,
      appRoutes.appSettingsRoute,
      appRoutes.userManagementRoute,

      appRoutes.structuralTopsidesRoute,
      appRoutes.caissonsRoute,

      { path: '**', redirectTo: 'not-found' }
    ]
  },

  { path: '**', redirectTo: 'not-found' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true,
    enableTracing: false,
    preloadingStrategy: PreloadAllModules
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }