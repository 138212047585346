<div class="row wrapper white-bg page-heading-with-description">
  <h2 class="pull-left p-s-none m-b-none">
    User Management
    <small class="sub-heading-small">Manage the list of users for the application using the table below.</small>
  </h2>
  <div class="pull-right m-t-xs m-r-xs">
    <tooltip-wrapper tooltip="Clear filters" position="left" [tooltipStyle]="{'margin-left': '-88px', 'margin-top': '-18px'}">
      <button class="btn btn-white btn-bitbucket" (click)="onClearFilters()">
        <span class="fa-stack remove-filter-icon">
          <i class="fa fa-filter fa-stack-1x"></i>
          <i class="fa fa-times fa-stack-1x"></i>
        </span>
      </button>
    </tooltip-wrapper>
  </div>
</div>

<div class="small-break"></div>
<orbit-loader *ngIf="isLoading"></orbit-loader>

<div class="row settings scrollable" [hidden]="isLoading">
  <ag-grid-angular #agGrid [rowData]="data" class="ag-theme-balham"
                   style="height: calc(100vh - 236px); min-height: 300px" [gridOptions]="gridOptions"
                   (rowSelected)="onRowSelected($event)" (rowDoubleClicked)="onRowDoubleClicked($event)">
  </ag-grid-angular>
</div>

<ng-template #footerContent>
  <div class="border-left p-l-sm p-r-sm" style="display:inline;">
    <button class="btn btn-warning btn-reset" (click)="onResetLocks()" [disabled]="isLoading">
      <i class="fas fa-retweet"></i> Reset Locks
    </button>
  </div>
  <div class="border-left p-l-sm" style="display:inline;">
    <button class="btn btn-success btn-action-extra-large" (click)="onGetActivationToken()" [disabled]="isLoading || !currentSelection">
      <i class="fas fa-user-check"></i> Get Activation Token
    </button>
  </div>
</ng-template>

<div bsModal #userModal="bs-modal" [config]="modalConfig" class="modal fade" tabindex="-1" role="dialog"
     aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Manage User</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="cancelChanges();"
                [disabled]="loadingService.isDataLoadingSomewhere">
          <span aria-hidden="true">&times;</span><span class="sr-only">Close</span>
        </button>
      </div>
      <form class="form-horizontal" (ngSubmit)="saveChanges()" *ngIf="updatedItem">
        <div class="modal-body">
          <div class="form-group">
            <div class="col-lg-12 no-padding">
              <input-wrapper labelText="Email" labelClass="col-lg-2" contentClass="col-lg-10"
                             tips="Please ensure the email address for the user is specified."
                             [model]="updatedItem.email" [validate]="modelValidator" validationProperty="email"
                             [forceValidation]="isValidating" [control]="email">
                <input type="text" placeholder="Email" class="form-control" name="updatedItem.email"
                       [(ngModel)]="updatedItem.email" #email="ngModel">
              </input-wrapper>
            </div>
          </div>
          <div class="form-group">
            <div class="col-lg-12 no-padding">
              <input-wrapper labelText="Full Name" labelClass="col-lg-2 place-center" contentClass="col-lg-10"
                             [model]="updatedItem.name" [validate]="modelValidator" validationProperty="name"
                             [forceValidation]="isValidating" [control]="name">
                <input type="text" placeholder="Full Name" class="form-control" name="updatedItem.name"
                       [(ngModel)]="updatedItem.name" #name="ngModel">
              </input-wrapper>
            </div>
          </div>
          <div class="form-group">
            <div class="col-lg-12 no-padding">
              <input-wrapper labelText="Level" labelClass="col-lg-2 place-center" contentClass="col-lg-10"
                             [model]="updatedItem.level" [validate]="modelValidator" validationProperty="level"
                             [forceValidation]="isValidating" [control]="level">
                <select class="form-control" name="updatedItem.userLevel" [(ngModel)]="updatedItem.level"
                        [compareWith]="compareById" #level="ngModel">
                  <option *ngFor="let level of userData.levels" [ngValue]="level">{{level.name}}</option>
                </select>
              </input-wrapper>
            </div>
          </div>
          <div class="form-group" [ngClass]="{'has-error': isValidating && !hasValidAssets()}" *ngIf="!isAdmin()">
            <label class="col-lg-2 control-label" [ngClass]="{'text-danger': isValidating && !hasValidAssets()}">Assets</label>
            <div class="col-lg-10">
              <ss-multiselect-dropdown [settings]="assetMultiSelectSettings" [texts]="assetMultiSelectTexts"
                                       [options]="userData.assets" name="updatedItem.assets" class="user-management-assets"
                                       [(ngModel)]="currentSelectionAssetIds" (ngModelChange)="onAssetsSelectChange($event)">
              </ss-multiselect-dropdown>

              <div *ngIf="isValidating && !hasValidAssets()" class="text-danger">
                At least one asset must be selected for non-Administrator users.
              </div>
            </div>
          </div>
          <div class="form-group m-b-none">
            <div class="col-lg-12 no-padding">
              <input-wrapper labelText="Status" labelClass="col-lg-2" contentClass="col-lg-10"
                             tips="Inactive users will be prevented from accessing the application, but data associated with them will be preserved.">
                <select class="form-control" name="updatedItem.isActive" [(ngModel)]="updatedItem.isActive">
                  <option [ngValue]="true">Active</option>
                  <option [ngValue]="false">Inactive</option>
                </select>
              </input-wrapper>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-white btn-action" (click)="cancelChanges();"
                  [disabled]="loadingService.isDataLoadingSomewhere">
            Close
          </button>
          <input type="submit" class="btn btn-primary btn-action" value="Save" [disabled]="loadingService.isDataLoadingSomewhere">
        </div>
      </form>
    </div>
  </div>
</div>