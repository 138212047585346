import { Component, ViewChild, ViewContainerRef, TemplateRef } from '@angular/core';
import { takeUntil } from 'rxjs/operators';

import { FooterService, FooterEvent } from '../../../services/footer.service';
import { LoadingService } from '../../../shared/services/loading.service';

import { SelfUnsubscriberBase } from '../../../shared/components/base/SelfUnsubscriber';

@Component({
  selector: 'orbit-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent extends SelfUnsubscriberBase {

  private _registeredViews: {
    id_templateRef: TemplateRef<any>, // Template ref of the element inserted in the footer
    options: { // extra options
      persistent: boolean // if TRUE, element will remain in footer even after clear()
    }
  }[] = []

  constructor(
    public footerService: FooterService,
    public loadingService: LoadingService
  ) {
    super();

    this.footerService.events
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(event => {
        if (!this.footerContainer) {
          return;
        }
        if (event.type === 'create') {
          const elementViewRef = event.template.createEmbeddedView(null);
          this._registerView(event);

          if (event.options && event.options.persistent) {
            this.footerContainer.insert(elementViewRef);
          } 
          else {
            this.footerContainer.insert(elementViewRef, 0);
          }
        } else if (event.type === 'destroy') {
          const regElement = this._registeredViews.find(rv => rv.id_templateRef.elementRef.nativeElement == event.template.elementRef.nativeElement);

          // If the element from the view currently being destroyed was persistent, remove it from the list of registered views
          if (!!regElement && !!regElement.options.persistent) {
            const index = this._registeredViews.indexOf(regElement);
            this._registeredViews.splice(index, 1);
          }

          // Clear the footer and add back all the remaining persistent elements
          this.footerContainer.clear();

          for (let regView of this._registeredViews.filter(rv => !!rv.options.persistent)) {
            const element = regView.id_templateRef.createEmbeddedView(null);
            this.footerContainer.insert(element);
          }

          // Update views array to leave only the persistent elements
          this._registeredViews = this._registeredViews.filter(rv => !!rv.options.persistent);
        } else {
          throw new Error(`Unknown event type - ${event.type}`);
        }
      });
  }

  @ViewChild('footerContainer', { read: ViewContainerRef, static: true }) footerContainer: ViewContainerRef;

  private _registerView(event: FooterEvent) {
    this._registeredViews.push(
      {
        id_templateRef: event.template,
        options: {
          persistent: !!event.options ? event.options.persistent : false
        }
      });
  }
}