import { Subject } from "rxjs";

export class GridTooltipHandler {
  private static tooltipData = new Subject<TooltipData>();

  static updateTooltipData(data: TooltipData) {
    this.tooltipData.next(data);
  }

  static getTooltipData(): Subject<TooltipData> {
    return this.tooltipData;
  }
}

export class TooltipData {
  srcElement: any;
  tooltip: string;
  hide : boolean = false;
}