import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CookieService } from 'ngx-cookie-service';

import { ApiService } from '../shared/services/api.service';
import { ApiCacheService } from '../shared/services/api-cache.service';

import { ApiErrorOptions } from '../shared/models/ApiErrorOptions';
import { User } from '../models/user/User';
import { PasswordSetupData } from '../models/user/PasswordSetup';
import { UserRelatedData } from '../models/user/UserRelatedData';
import { Credentials } from '../models/user/Credentials';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private _apiBaseUrl: string = `api/Users`;

  private _userCacheService = new ApiCacheService<User>(this.apiService, `${this._apiBaseUrl}/Details`,
    new ApiErrorOptions({
      errorPrefix: 'An error occurred while loading user data. Error details:',
      errorSuffix: `Please refresh the page and try again.`,
      showConfirmButton: true
    }));

  constructor(
    private apiService: ApiService,
    private router: Router,
    private jwtHelper: JwtHelperService,
    private cookieService: CookieService
  ) {
  }

  getUser(): Observable<User> {
    if (!this.isAuthenticated()) {
      return undefined;
    }
    return this._userCacheService.get()
      .pipe(catchError(err => {
        this.logout();
        return Observable.throw(err);
      }));
  }

  getUsers(): Observable<User[]> {
    return this.apiService.makeGet<User[]>(`${this._apiBaseUrl}`,
      new ApiErrorOptions({
        errorPrefix: 'Unable to load users. Error details:'
      }));
  }

  getUserRelatedData(): Observable<UserRelatedData> {
    return this.apiService.makeGet<UserRelatedData>(`${this._apiBaseUrl}/RelatedData`,
      new ApiErrorOptions({
        errorPrefix: 'Unable to load users related data. Error details:'
      }));
  }

  updateUserData(user: User): Observable<User> {
    if (!!user.id) {
      // update
      return this.apiService.makePut(`${this._apiBaseUrl}`, user,
        new ApiErrorOptions({
          errorPrefix: 'An error occurred while saving changes. Error details:',
          showConfirmButton: true
        }));
    }

    // add
    return this.apiService.makePost(`${this._apiBaseUrl}`, user,
      new ApiErrorOptions({
        errorPrefix: 'An error occurred while adding the new user. Error details:',
        showConfirmButton: true
      }));
  }

  deleteUser(id: number): Observable<void> {
    return this.apiService.makeDelete(`${this._apiBaseUrl}/${id}`,
      new ApiErrorOptions({ errorPrefix: 'An error occurred while deleting the selected user. Error details:' }));
  }

  //#region Login & Auth

  login(credentials: Credentials) {
    return this.apiService.makePost<User>(`${this._apiBaseUrl}/Login`, credentials,
      new ApiErrorOptions({
        errorPrefix: 'An error occurred while logging in. Error details:',
        showConfirmButton: true
      }));
  }

  logout() {
    this.cookieService.delete('Authorization');
    this._userCacheService.clear();
    this.router.navigate(['/login']);
  }

  isAuthenticated(): boolean {
    let token = this.cookieService.get('Authorization');
    token = token ? token.includes(' ') ? token.split(' ')[1] : token.split('%20')[1] : token;
    if (token) {
      return !this.jwtHelper.isTokenExpired(token);
    } else {
      return false;
    }
  }

  resetPassword(id: number): Observable<any> {
    return this.apiService.makeGet<any>(`${this._apiBaseUrl}/Reset/${id}`,
      new ApiErrorOptions({ errorPrefix: 'An error occurred while resetting the account password. Error details:' }));
  }

  getActivationToken(id: number): Observable<any> {
    return this.apiService.makeGet<any>(`${this._apiBaseUrl}/ActivationToken/${id}`,
      new ApiErrorOptions({ errorPrefix: 'An error occurred while getting activation token for this user. Error details:' }));
  }

  setupPassword(data: PasswordSetupData): Observable<any> {
    return this.apiService.makePut<any>(`${this._apiBaseUrl}/AccountSetup`, data,
      new ApiErrorOptions({ errorPrefix: 'An error occurred while setting up password for this account. Error details:' }));
  }

  changePassword(data: PasswordSetupData): Observable<any> {
    return this.apiService.makePut<any>(`${this._apiBaseUrl}/ChangePassword`, data,
      new ApiErrorOptions({ errorPrefix: 'An error occurred while changing password for this account. Error details:' }));
  }

  resetLockouts(): Observable<any> {
    return this.apiService.makePost<any>(`${this._apiBaseUrl}/ResetLockouts`, undefined,
      new ApiErrorOptions({ errorPrefix: 'An error occurred while trying to reset the locked accounts. Error details:' }));
  }

  //#endregion

  hasAccess(minLevelId: number, currentLevelId: number): boolean {
    return currentLevelId >= minLevelId;
  }
}