<div class="status-container" [ngSwitch]="status">
  <div *ngSwitchCase="'new'">
    <i class="fa fa-plus-circle new"></i>
    <div>New</div>
  </div>
  <div *ngSwitchCase="'approved'">
    <i class="fa fa-check-circle approved"></i>
    <div>Approved</div>
  </div>
  <div *ngSwitchCase="'pending'">
    <i class="fa fa-minus-circle pending"></i>
    <div>Pending</div>
  </div>
  <div *ngSwitchCase="'rejected'">
    <i class="fa fa-times-circle rejected"></i>
    <div>Rejected</div>
  </div>
  <div *ngSwitchCase="'cancelled'">
    <i class="fa fa-times-circle rejected"></i>
    <div>Cancelled</div>
  </div>
  <div *ngSwitchDefault>
    <i class="fa fa-question-circle"></i>
    <div>Unknown value</div>
  </div>
</div>
