import { Injectable, TemplateRef } from '@angular/core';
import { ReplaySubject, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  events = new ReplaySubject<{
    template: TemplateRef<any>,
    type: 'create' | 'destroy'
  }>(1);

  injectView(template: TemplateRef<any>): Subscription {
    this.events.next({
      template, type: 'create'
    });
    return new Subscription(() => {
      this.events.next({
        template, type: 'destroy'
      });
    });
  }
}