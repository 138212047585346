export class Constants {
  public static Modals = {
    ERROR_TITLE: 'Error!',
    LOADING_TITLE: 'Processing...'
  }

  public static StartYear: number = 2021;

  public static MimeTypes = {
    IMAGES: 'image/*',
    EXCEL: 'xls',
    FULL_EXCEL: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  }
}