import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { finalize, takeUntil, catchError } from 'rxjs/operators';

import { UserService } from '../../../services/user.service';
import { ValidationService } from '../../../shared/services/validation.service';
import { DialogService } from '../../../shared/services/dialog.service';

import { LoadableComponentBase } from '../../../shared/components/base/LoadableComponent';

import { PasswordSetupData } from '../../../models/user/PasswordSetup';

import { getErrorMessage } from '../../../shared/utils/Utils';

@Component({
  selector: 'orbit-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent extends LoadableComponentBase implements OnInit {

  constructor(
    private validationService: ValidationService,
    private userService: UserService,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    public modalService: BsModalService
  ) {
    super();
  }

  data: PasswordSetupData;
  isValidating: boolean = false;

  ngOnInit() {
    this.data = new PasswordSetupData();
    this.data.token = this.route.snapshot.params.token;
  }

  onSubmit() {
    this.isValidating = true;
    if (!this.isModelValid()) return;
    this.isValidating = false;

    this.startLoader();
    this.userService.getUser()
      .pipe(
        takeUntil(this.ngUnsubscribe),
        finalize(() => this.stopLoader()),
        catchError(err => this.dialogService.showError('Error occurred!', getErrorMessage(err)))
      )
      .subscribe(user => {
        this.data.email = user.email;
        this.userService.changePassword(this.data)
          .pipe(
            takeUntil(this.ngUnsubscribe),
            finalize(() => this.stopLoader()),
            catchError(err => this.dialogService.showError('Error occurred!', getErrorMessage(err)))
          )
          .subscribe(_ => {
            this.modalService.hide(1);
            this.dialogService.showDialog('Success!', 'Your password was successfully changed.', 'success');
          });
      });
  }

  isModelValid(): boolean {
    return !!this.data
      && !!this.data.oldPassword
      && !!this.data.newPassword
      && !!this.data.confirmPassword
      && this.data.newPassword == this.data.confirmPassword
      && !this.validationService.validatePasswordStrength(this.data.newPassword);
  }

  get modelValidator() {
    return this.validateModel.bind(this);
  }

  validateModel(prop: string): string {
    switch (prop) {
      case 'newPassword':
        return this.validationService.validatePasswordStrength(this.data.newPassword);
      case 'confirmPassword':
        const reqError = this.validationService.validateRequired(this.data[prop]);
        if (!!reqError) return reqError;
        return this.validationService.validatePasswordConfirmation(this.data.newPassword, this.data.confirmPassword);
      default:
        return this.validationService.validateRequired(this.data[prop]);
    }
  }

  close() {
    this.modalService.hide(1);
  }
}