import { Component, Input } from '@angular/core';

@Component({
  selector: 'orbit-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {

  @Input() position = 'fixed';
  @Input() invertColors = false;

}