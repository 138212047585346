<div id="wrapper" style="overflow:hidden;">
  <orbit-sidenav></orbit-sidenav>
  <div id="page-wrapper" class="gray-bg">
    <orbit-header [user]="user"></orbit-header>
    <div class="row">
      <div class="col-lg-12">
        <div class="wrapper wrapper-content">
          <router-outlet></router-outlet>
          <ng-container #modalContainer>
          </ng-container>
          <orbit-footer></orbit-footer>
        </div>
      </div>
    </div>
  </div>
</div>