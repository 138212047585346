import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { GridInspection } from './GridInspection';
import { TooltipData, GridTooltipHandler } from '../../../utils/GridTooltipHandler';

@Component({
  selector: 'shared-grid-tooltip-wrapper',
  templateUrl: './grid-tooltip-wrapper.component.html',
  styleUrls: ['./grid-tooltip-wrapper.component.scss']
})
export class GridTooltipWrapperComponent implements ICellRendererAngularComp {

  inspections: GridInspection[];
  position: string = 'left';

  constructor() { }

  agInit(params: any): void {
    if (!params.value) return;

    this.inspections = params.value;

    for (let insp of this.inspections) {
      if (insp.type) {
        const inspTitle = insp.type.toLowerCase().replace(/[^A-Za-z0-9 ]/g, '').replace(' ', '-');
        insp.cellClass = `inspection-${inspTitle}`;
      }
    }
  }

  onMouseOver(event, tooltip: string) {
    const data = new TooltipData();
    data.srcElement = event.srcElement;
    data.tooltip = tooltip;

    GridTooltipHandler.updateTooltipData(data);
  }

  onMouseLeave(_) {
    const data = new TooltipData();
    data.hide = true;

    GridTooltipHandler.updateTooltipData(data);
  }

  refresh(): boolean {
    return false;
  }
}