import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({ name: 'timespan' })
export class TimespanPipe implements PipeTransform {
  transform(value: string, params?: string): string | boolean {
    if (!value) {
      return value;
    }
    const inputDuration = moment.duration(value);
    if (params) {
      const indexOfFirstDigit = params.search(/\d/);
      const comparator = params.substr(0, indexOfFirstDigit);
      const comparatorDuration = moment.duration(params.substr(indexOfFirstDigit));
      switch (comparator) {
        case '=':
          return inputDuration === comparatorDuration;
        case '<':
          return inputDuration < comparatorDuration;
        case '>':
          return inputDuration > comparatorDuration;
        case '<=':
          return inputDuration <= comparatorDuration;
        case '>=':
          return inputDuration >= comparatorDuration;
        default:
          throw new Error(`Unknown timespan comparator '${comparator}'`);
      }
    }
    const generateTimeUnit = (args: { numberOfUnits: number, unit: string }) => {
      const { numberOfUnits, unit } = args;
      return numberOfUnits <= 0 ? '' : `${numberOfUnits} ${unit}${numberOfUnits === 1 ? '' : 's'}`;
    };
    const duration = [
      { numberOfUnits: inputDuration.hours(), unit: 'hour' },
      { numberOfUnits: inputDuration.minutes(), unit: 'minute' }
    ].map(generateTimeUnit).join(' ');
    return inputDuration > moment.duration(1, 'm') ? `in${duration}` : 'now';
  }
}
