import { OnDestroy } from '@angular/core';
import { Subject, Subscription, Observable } from 'rxjs';
import { of } from 'rxjs';
import { clone } from '../utils/Utils';
import { ApiErrorOptions } from '../models/ApiErrorOptions';
import { ApiService } from './api.service';

export class ApiCacheService<T> implements OnDestroy {
  constructor(
    private apiService: ApiService,
    private url: string,
    private errorOptions: ApiErrorOptions
  ) { }

  private data: T;
  private isRequesting: boolean;
  private subjects: Subject<T>[] = [];
  private requestSubscription: Subscription;

  get(): Observable<T> {
    if (this.data) {
      return of(clone(this.data));
    }
    if (!this.isRequesting) {
      this.request();
    }
    const subject = new Subject<T>();
    this.subjects.push(subject);
    return subject;
  }

  clear(): void {
    if (this.requestSubscription) {
      this.requestSubscription.unsubscribe();
    }

    this.data = undefined;
    this.isRequesting = undefined;
    this.subjects = [];
    this.requestSubscription = undefined;
  }

  private request() {
    this.isRequesting = true;
    this.requestSubscription = this.apiService.makeGet<T>(this.url, this.errorOptions)
      .subscribe(d => {
        this.isRequesting = false;
        this.data = d;
        this.subjects.forEach(s => s.next(clone(this.data)));
      }, e => {
        this.isRequesting = false;
        this.subjects.forEach(s => s.error(e));
      }, () => {
        this.isRequesting = false;
        this.subjects.forEach(s => s.complete());
        this.subjects = [];
      });
  }

  ngOnDestroy(): void {
    this.clear();
  }
}
