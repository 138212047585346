export function riskCellRenderer(content: string, className: string): HTMLElement {
  const result = document.createElement('div');
  result.className = `risk-score risk-cell-fill ${className}`;
  result.innerHTML = content;
  return result;
}

export function riskComparator(firstValue: { value: number }, secondValue: { value: number }) {
  if (!firstValue && !secondValue) return 0;
  if (!firstValue) return -1;
  if (!secondValue) return 1;
  return firstValue.value - secondValue.value;
}