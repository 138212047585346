import { DateFormatter, DecimalFormatter } from './Formatters';
import { SetFilterComponent } from '../components/ag-grid/filters/set-filter/set-filter.component';
import { ButtonCellRendererComponent } from '../components/ag-grid/renderers/button-cell-renderer/button-cell-renderer.component';
import { GridTooltipWrapperComponent } from '../components/ag-grid/grid-tooltip-wrapper/grid-tooltip-wrapper.component';
import { DateRangeFilterComponent } from '../components/ag-grid/filters/date-range-filter/date-range-filter.component';
import { riskComparator } from '../components/ag-grid/renderers/risk-cell-renderer';

export class AgGridUtils {

  public static addDefaultColumnTypes(columnTypes: any) {
    columnTypes['dateColumn'] = this.getDateColumn();
    columnTypes['numberColumn'] = this.getNumberColumn();
    columnTypes['decimalColumn'] = this.getDecimalColumn(2);
    columnTypes['setColumn'] = this.getSetColumn();
    columnTypes['centerColumn'] = this.getCenterColumn();
    columnTypes['buttonColumn'] = this.getButtonColumn();
    columnTypes['wrapColumn'] = this.getWrapColumn();

    columnTypes['frequencyColumn'] = this.getFrequencyColumn();
    columnTypes['planColumn'] = this.getPlanColumn();
    columnTypes['planTooltipColumn'] = this.getPlanTooltipColumn();
    columnTypes['centerWithBorder'] = this.getCenterWithBordersColumn();
  }

  public static getColumnTypes() {
    return {
      ...this.getDateColumn(undefined),
      ...this.getNumberColumn(undefined),
      ...this.getSetColumn(undefined),
      ...this.getCenterColumn(undefined),
      ...this.getWrapColumn(),
    }
  }

  public static getDateColumn(cellRendererFunc?: (d: { value: any }) => any) {
    return {
      filter: 'dateRangeFilter',
      valueFormatter: DateFormatter(),
      resizable: false,
      cellClass: 'text-center',
      headerClass: 'text-center',
      minWidth: 110,
      width: 110,
      cellRenderer: cellRendererFunc
    };
  }

  public static getNumberColumn(cellRendererFunc?: (d: { value: any }) => any) {
    return {
      filter: 'agNumberColumnFilter',
      cellClass: 'text-right',
      cellRenderer: cellRendererFunc,
    }
  }

  public static getDecimalColumn(numberOfDecimals: number, cellRendererFunc?: (d: { value: any }) => any) {
    return {
      filter: 'agNumberColumnFilter',
      cellClass: 'text-right',
      valueFormatter: DecimalFormatter(numberOfDecimals),
      cellRenderer: cellRendererFunc,
    }
  }

  public static getSetColumn(cellRendererFunc?: (d: { value: any }) => any) {
    return {
      filter: 'setFilter',
      cellRenderer: cellRendererFunc
    }
  }

  public static getCenterColumn(cellRendererFunc?: (value: any) => any) {
    return {
      cellClass: 'text-center',
      headerClass: 'text-center',
      cellRenderer: cellRendererFunc
    }
  }

  public static getCenterWithBordersColumn(cellRendererFunc?: (value: any) => any) {
    return {
      cellRenderer: cellRendererFunc,
      cellClass: 'border-left-cat text-center',
      headerClass: 'border-left-cat text-center',
      minWidth: 150,
      width: 150
    }
  }
  

  public static getWrapColumn() {
    return {
      cellClass: 'text-wrap',
      autoHeight: true
    }
  }

  public static getRiskColumn(cellRendererFunc: (d: { value: any }) => any) {
    return {
      cellRenderer: cellRendererFunc,
      comparator: riskComparator,
      resizable: false,
      filterParams: { displayField: 'abbreviation', blankValueLabel: 'N/A' },
      headerClass: 'text-center',
      minWidth: 110,
      width: 110,
    }
  }

  public static getFrequencyColumn(cellRendererFunc?: (d: { value: any }) => any) {
    return {
      cellRenderer: cellRendererFunc,
      cellClass: 'text-center',
      headerClass: 'text-center',
      filter: 'agNumberColumnFilter',
      valueFormatter: v => AgGridUtils.formatNAValue(v.value),
      resizable: false,
      minWidth: 100,
      width: 100,
    };
  }

  public static getLoFColumn(cellRendererFunc?: (d: { value: any }) => any) {
    return {
      cellRenderer: cellRendererFunc,
      comparator: riskComparator,
      resizable: false,
      filterParams: { displayField: 'abbreviation', blankValueLabel: 'N/A' },
      headerClass: 'text-center',
      minWidth: 110,
      width: 110,
    };
  }

  public static getCoFColumn(cellRendererFunc?: (d: { value: any }) => any) {
    return {
      cellRenderer: cellRendererFunc,
      comparator: riskComparator,
      resizable: false,
      filterParams: { displayField: 'abbreviation', blankValueLabel: 'N/A' },
      headerClass: 'text-center',
      minWidth: 100,
      width: 100,
    };
  }

  public static getPlanColumn(cellRendererFunc?: (d: { value: any }) => any) {
    return {
      cellRenderer: cellRendererFunc,
      resizable: false,
      minWidth: 80,
      width: 80,
      maxWidth: 80
    };
  }

  public static getPlanTooltipColumn() {
    return {
      cellRendererFramework: GridTooltipWrapperComponent,
      resizable: false,
      minWidth: 80,
      width: 80,
      maxWidth: 80
    };
  }

  public static getButtonColumn() {
    return {
      cellRenderer: 'buttonCellRenderer',
      minWidth: 100,
      width: 100,
      maxWidth: 100,
      filter: false,
      sortable: false,
      resizable: false
    };
  }

  public static setFixedWidth(column: any, width: number) {
    column.width = width;
    column.minWidth = width;
    column.maxWidth = width;
    column.resizable = false;
  }

  public static gridFilters = {
    setFilter: SetFilterComponent,
    dateRangeFilter: DateRangeFilterComponent
  };

  public static gridRenderers = {
    buttonCellRenderer: ButtonCellRendererComponent,
  }

  public static formatNAValue(value: any) {
    return value ? value : 'N/A';
  }

  public static replaceMultilineText(value: string, replaceWith: string = ' ') {
    return !value ? value : value.replace(/(?:\r\n|\r|\n)/g, replaceWith);
  }
}
