import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  private isSidebarCollapsed: boolean = false;
  sidebarStateChanged: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() { }

  changeSidebarState() {
    this.isSidebarCollapsed = !this.isSidebarCollapsed;
    this.sidebarStateChanged.next(this.isSidebarCollapsed);
  }

  getSidebarState(): boolean {
    return this.isSidebarCollapsed;
  }
}