import { Component, Inject, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'shared-tabbed-view',
  templateUrl: './tabbed-view.component.html',
  styleUrls: ['./tabbed-view.component.scss']
})
export class TabbedViewComponent implements OnInit {
  constructor(@Inject(Router) private router: Router) {
  }

  @Input() tabs: Tab[];

  ngOnInit() { }

  isTabActive(tab: Tab) {
    if (!tab) {
      return false;
    }
    const currentUrl = this.router.routerState.snapshot.url;
    return currentUrl.indexOf(`/${tab.path}`) > 0;
  }
}

export class Tab {
  name: string;
  path: string;
  icon: string;
}
