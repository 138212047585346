import { GridOptions, GridApi, Column, ValueService, ICellRendererFunc, ColDef } from 'ag-grid-community';
import { AgGridUtils } from '../../utils/AgGridUtils';
import * as moment from 'moment';

const maxColWidth = 100;

export const gridOneLineHeight = 30;

export const getGridOptions: (options?: GridOptions) => GridOptions = (options?: GridOptions) => {
  const frameworkComponents = {};
  Object.assign(frameworkComponents, AgGridUtils.gridFilters);
  Object.assign(frameworkComponents, AgGridUtils.gridRenderers);
  return Object.assign({
    suppressMovableColumns: true,
    stopEditingWhenGridLosesFocus: true,
    animateRows: false,
    defaultColDef: {
      resizable: true,
      filter: true,
      sortable: true,
      editable: false,
      filterParams: { newRowsAction: 'keep' }
    },
    multiSortKey: 'ctrl',
    rowSelection: 'single',
    frameworkComponents: frameworkComponents,
    headerHeight: 40,
    rowHeight: gridOneLineHeight,
    //tooltipShowDelay: 0,
    //tooltipMouseTrack: true,
    onGridReady: params => {
      setTimeout(_ => {
        params.api.resetRowHeights();
      }, 0);
    },
    onGridSizeChanged: e => {
      e.api.resetRowHeights();
      if (e.clientWidth > 0) {
        setTimeout(_ => {
          e.api.sizeColumnsToFit();
        }, 0);
      }
    },
    onColumnResized: e => {
      e.api.resetRowHeights();
    },
    onRowDataChanged: e => {
      e.api.resetRowHeights();
    },
    onRowDataUpdated: e => {
      e.api.resetRowHeights();
    }
  } as GridOptions, options);
};

export function calculateGridRowHeight(params: any) {
  const api = params.api as GridApi;
  if (!api) {
    return gridOneLineHeight;
  }
  const columns = <Column[]>params.node.columnController.gridColumns;
  const valueService = <ValueService>(api as any).valueService;
  const columnValues = columns.map(c => {
    const colDef = c.getColDef();
    let val = valueService.getValue(c, params.node);
    if (colDef.cellRenderer && typeof colDef.cellRenderer === 'function') {
      const htmlElement = (colDef.cellRenderer as ICellRendererFunc)({ value: val, data: params.data }) as HTMLElement;
      return {
        column: c,
        value: typeof (htmlElement) === 'string' ? htmlElement : htmlElement.innerText
      };
    } else {
      const renderers = api.getCellRendererInstances({
        columns: [c],
        rowNodes: [params.node]
      });
      if (renderers.length) {
        const renderer = renderers[0];
        const htmlElement = renderer.getGui();
        return {
          column: c,
          value: htmlElement.nodeName === 'APP-BUTTON-CELL-RENDERER' ? '' : htmlElement.innerText
        };
      } else {
        if (colDef.valueFormatter) {
          val = colDef.valueFormatter({
            api: api,
            colDef: colDef,
            column: c,
            columnApi: (c as any).columnApi,
            context: params.context,
            data: params.data,
            node: params.node,
            value: val
          });
        } else {
          val = val ? typeof (val) !== 'string' ? JSON.stringify(val) : val : val;
        }
        return {
          column: c,
          value: val
        };
      }
    }
  });
  return Math.max(...columnValues.map(cv => {
    if (!cv.value) {
      return gridOneLineHeight;
    }
    const columnCharWidth = cv.column.getActualWidth() / 6.5;
    const lines = splitIntoLines(cv.value);
    let totalLength = lines.length;
    totalLength += lines.map(l => l ? Math.floor(getCharLength(l) / columnCharWidth) : 0).reduce((prev, cur) => prev + cur);
    return 5 + 25 * totalLength;
  }));
}

export function setColumnsWidthToFullLength(e) {
  const colDefs: ColDef[] = [];

  let fullLengthColumns = 0;
  let fixedWidth = 0;

  e.columnApi.getAllColumns().forEach(column => {
    const colDef = column.getColDef();
    if (!colDef.refData || colDef.refData.fullLength != 'true') {
      fixedWidth += colDef.width ? colDef.width : (colDef.maxWidth ? colDef.maxWidth : maxColWidth);
    }
    else if (colDef.refData && colDef.refData.fullLength == 'true') {
      fullLengthColumns++;
    }
    colDefs.push(colDef);
  });
  const maxWidth = (e.clientWidth - fixedWidth) / fullLengthColumns - 2;

  colDefs.forEach(c => c.width = (c.refData && c.refData.fullLength) ? maxWidth : c.width);
  e.api.setColumnDefs(colDefs);
  e.api.resetRowHeights();
}

export function stringify(obj: any): string {
  return JSON.stringify(obj, null, 2);
}

export function renderJsonObject(d) {
  if (!d.value) {
    return d.value;
  }
  return stringify(d.value);
}

export function spaceCapitalizedString(capitalizedString: string): string {
  if (!capitalizedString) {
    return capitalizedString;
  }
  const insertionPoints = [];
  for (let i = 0; i < capitalizedString.length; i++) {
    if (i > 0 && capitalizedString[i - 1] !== '[' && capitalizedString[i].match(/[A-Z]/)) {
      if (capitalizedString[i - 1].match(/[a-z]/)) {
        // if previous letter is lowercase insert point
        insertionPoints.push(i);
      }
      else if (i != capitalizedString.length - 1 && capitalizedString[i + 1].match(/[a-z]/)) {
        // if previous letter is UPERCASE and next letter is lowercase
        insertionPoints.push(i);
      }
    }
  }
  insertionPoints.push(capitalizedString.length);
  let previousPoint = 0;
  return insertionPoints.map(point => {
    const prevPoint = previousPoint;
    previousPoint = point;
    return capitalizedString.substr(prevPoint, (point - prevPoint));
  }).join(' ');
}


export function toPrettyPrint(object: any, config: { arrayBrackets: boolean, keySpacing: 'none' | 'capital', includeStringQuotes: boolean }, indentLevel = 1): string {
  const stringifyObject = (obj) => {
    if (obj === undefined || obj === null) {
      return 'undefined';
    }
    switch (typeof (obj)) {
      case 'string':
        if (/^[0-9]+$/g.test(obj)) {
          return parseFloat(obj);
        }
        const date = moment(obj, moment.ISO_8601, true);
        return date.isValid() ? date.format('DD/MM/YYYY') :
          config.includeStringQuotes ? (config.arrayBrackets && `${obj}`.endsWith("]") ? `"${obj}`.replace(']', '"]') : `"${obj}"`) : obj;
      case 'number':
        return obj;
      case 'object':
        return `${new Array(4 * indentLevel).join(' ')}${toPrettyPrint(obj, config, indentLevel + 1)}`;
      default:
        return obj;
    }
  };
  if (Array.isArray(object)) {
    const objectString = object.map(stringifyObject).join('\r\n');
    return config.arrayBrackets ? `[\r\n${objectString}\r\n]` : objectString;
  } else {
    return Object.keys(object).map(key => `${config.keySpacing === 'capital' ? spaceCapitalizedString(key) : key} = ${stringifyObject(object[key])}`).join('\r\n');
  }
}

export function renderToPrettyPrint(d, config: { arrayBrackets: boolean, keySpacing: 'none' | 'capital', includeStringQuotes: boolean }) {
  if (!d) {
    return '';
  }
  return toPrettyPrint(d, config);
}

export function getRowHeight(getNumberOfRows: (rowData: any) => number) {
  return params => 11 + 19 * getNumberOfRows(params.data);
}

export function getCharLength(stringValue: string): number {
  if (!stringValue) {
    return 0;
  }
  const tabMatches = stringValue.match(/\t/g);
  return stringValue.length + (tabMatches ? tabMatches.length * 4 : 0);
}

export function trimRows(stringValue: string): string {
  if (!stringValue) {
    return stringValue;
  }
  return stringValue.replace(/\r\n|\r|\n/g, '');
}

export function splitIntoLines(stringValue: string): string[] {
  if (!stringValue) {
    return [stringValue];
  }
  return stringValue.toString().split(/\r\n|\r|\n/);
}

export function getNumberOfLines(stringValue: string): number {
  return splitIntoLines(stringValue).length;
}
